import React from 'react'
import { useSelector } from "react-redux";
import User from '../../../assets/img/iconos/UserIcon.svg';
import AcordeonFormulario from './AcordeonFormulario';

export default function FormularioRetro({funcion}) {

  const storeJefe = useSelector((state) => state.rutaJefe);

  var obj = { fileName: '', fileUrl: User };
  if (typeof (storeJefe.dataUsuario.foto) == "string") {
       try {
            obj = JSON.parse(storeJefe.dataUsuario.foto)
       } catch (e) { }
  }

  return (
    <>
      <div className="row aire">
        <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-end justify-content-start">{/** RESPONSIVO */}
          <span className='tituloVistaJefe'>RETROALIMENTA A</span>
        </div>
      </div>
      <div className="row">
        <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center divAmarilloUsuarioRetroJefe">{/** RESPONSIVO */}
          <div className="col-auto"><img src={obj.fileUrl} className="fotoUsuariosJefe" width="60" height="60" alt="Perfil usuario" /></div>
          <div className="col">
            <span className='textoDivAmarilloUsuarioRetroJefe'>{storeJefe.dataUsuario.nombre + ' ' + storeJefe.dataUsuario.apellido_paterno + ' ' + storeJefe.dataUsuario.apellido_materno}</span>
          </div>
        </div>
      </div>
      <div className="row menosAire">
        <div className="offset-1 offset-lg-2 col-auto d-flex align-items-center justify-content-center divNegroUsuarioRetroJefe">{/** RESPONSIVO */}
          <div className="col-auto d-flex align-items-center justify-content-center">
            <i className="fas fa-user-friends usuariosPendientesPorCalificarDivIconoJefe"></i>
          </div>
          <div className="col-11">
            <span className='toolbarTextoDivUsuariosJefe'>Evaluarás: Unidad {storeJefe.dataUnidad.orden+1} - {storeJefe.dataUnidad.nombre}</span>
          </div>
        </div>
      </div>
      <div className="row aire">
        <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
          <p className='textoEncabezadoRetroJefe'>Durante las últimas semanas, con ayuda de la guía que te proporcionamos, has estado observando al participante para determinar qué tanto está aplicando lo que ha aprendido en esta Unidad del programa LIFT.</p>
        </div>
      </div>
      <div className="row">
        <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
          <p className='textoEncabezadoRetroJefe'>Utiliza el siguiente formato para preparar tu sesión uno a uno de retroalimentación de desempeño. Para ello deberás hacer 2 cosas:</p>
        </div>
      </div>
      <div className="row">
        <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
          <p className='textoEncabezadoRetroJefe'>A. Evaluar al participante.<br/>B. Preparar la sesión de retroalimentación.</p>
        </div>
      </div>
      <div className="row">
        <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
          <p className='textoEncabezadoRetroJefe'>Se asignará un puntaje a cada una de tus respuestas y se sumarán para obtener un promedio, el cual será la calificación que tu colaborador tendrá.<br/> El puntaje para cada respuesta es: 
          </p>
        </div>
      </div>
      <div className="row">
        <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
          <div className="row">
            <div className="col d-flex align-items-center justify-content-center">
              <span className='etiquetaRubrica'>Siempre = 10</span>
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <span className='etiquetaRubrica'>Casi siempre = 8</span>
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <span className='etiquetaRubrica'>Habitualmente = 6</span>
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <span className='etiquetaRubrica'>Ocasionalmente = 4</span>
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <span className='etiquetaRubrica'>Casi nunca = 2</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row aire">
        <div className="col offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center">{/** RESPONSIVO */}
          <div className="col-auto d-flex align-items-center justify-content-center cuadroAmarilloLetraRetroJefe divApartadoRetroJefe">
            <span className='letraRetroJefe'>A</span>
          </div>
          <div className="col d-flex align-items-center justify-content-start cuadroGrisLetraRetroJefe divApartadoRetroJefe">
            <span className='letraTextoRetroJefe'>EVALUA AL PARTICIPANTE</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
          <AcordeonFormulario funcion={funcion}/>
        </div>
      </div>
      <div className="row aire">
        <div className="col offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center">{/** RESPONSIVO */}
          <div className="col-auto d-flex align-items-center justify-content-center cuadroAmarilloLetraRetroJefe divApartadoRetroJefe">
            <span className='letraRetroJefe'>B</span>
          </div>
          <div className="col d-flex align-items-center justify-content-start cuadroGrisLetraRetroJefe divApartadoRetroJefe">
            <span className='letraTextoRetroJefe'>PREPARA TU SESIÓN DE RETROALIMENTACIÓN</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
          <div className="row aire">
            <div className="col">
              <p className='textoEncabezadoRetroJefe'>Antes de retroalimentar al colaborador, identifica ejemplos concretos de lo que calificaste en el paso anterior, así como sugerencias para ayudarlo a mejorar. Acompaña tus observaciones con recomendaciones y acciones para impulsar el perfeccionamiento de sus competencias.</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className='textoEncabezadoRetroJefe'>Para saber cómo estructurar y otorgar la retroalimentación, puedes consultar la “Guía de retroalimentación” que se encuentra en el apartado de “Herramientas”.</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className='textoEncabezadoRetroJefe'>Además de tus observaciones y sugerencias, te recomendamos revisar los resultados que el colaborador obtuvo en su Reto on the Job. Esto te ayudará a saber cómo ha aplicado sus conocimientos en situaciones prácticas y del día a día.</p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </>
  )
}
