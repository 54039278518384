import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { CONSTANT } from '../../../static/constants';
import { useAxios } from 'use-axios-client';
import { Donut } from 'react-dial-knob';
import AcordeonEvaluacionJefe from 'components/frontend/detalleJefeLearner/AcordeonEvaluacionJefe';
import AcuerdosJefe from 'components/frontend/detalleJefeLearner/AcuerdosJefe';
import { Link } from "react-router-dom";
import ResultadoEvaluacion from '../retoJob/ResultadoEvaluacion';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import flechaAcordeon from '../../../assets/img/iconos/flechaAcordeon.svg';
import User from '../../../assets/img/iconos/UserIcon.svg';
import '../retoJob/RetoJob.css';

export default function DetallesUsuario() {

     const dispatch = useDispatch();
     const storeJefe = useSelector((state) => state.rutaJefe);

     const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);

     const {data: dataUsuario, error: errorUsuario, loading: loadingUsuario } = useAxios({
          url: process.env.REACT_APP_API_URL + `/api/data_usuario/${storeJefe.usuario}`,
          headers: {
              'Authorization': `${token}` 
          }
     })

     const {data: dataUsuario2, error: errorUsuario2, loading: loadingUsuario2 } = useAxios({
          url: process.env.REACT_APP_API_URL + `/api/get_detalle/${storeJefe.usuario}`,
          headers: {
              'Authorization': `${token}` 
          }
     })

     if(loadingUsuario || loadingUsuario2) return(<div className="loadingPage"><LoadingSpinner /></div>)
     
     if(errorUsuario || !dataUsuario) return "Error!";
     if(errorUsuario2|| !dataUsuario2) return "Error!";



     var obj = { fileName: '', fileUrl: User };
     if (typeof (dataUsuario.data.foto) == "string") {
          try {
               obj = JSON.parse(dataUsuario.data.foto)
          } catch (e) { }
     }

     const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

     return (
          <>
              <div className="row menosAire">
               <div className="col-4 d-flex align-items-center justify-content-center">
                    <button className={'btn btn-link btn-menuJefe'} onClick={(e) => dispatch({ type: CONSTANT.RUTA_JEFE_SET_VISTA_ACTIVE, payload: {vista: 'uno'} }) }>
                         <div className="row">
                              <div className="col d-flex align-items-center justify-content-center"><span className='textoMenuJefe'>{'< Regresar a Mis Equipos'}</span></div>
                         </div>
                    </button>
                    </div>
               </div>
               <div className="row aire">
                    <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-end justify-content-start">{/** RESPONSIVO */}
                         <span className='tituloVistaJefe'>AVANCE DE</span>
                    </div>
               </div>
               <div className="row">
                    <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center divAmarilloUsuarioRetroJefe">{/** RESPONSIVO */}
                         <div className="col-auto"><img src={obj.fileUrl} className="fotoUsuariosJefe" width="60" height="60" alt="Perfil usuario" /></div>
                         <div className="col">
                              <span className='textoDivAmarilloUsuarioRetroJefe'>{dataUsuario.data.nombre + ' ' + dataUsuario.data.apellido_paterno + ' ' + dataUsuario.data.apellido_materno}</span>
                         </div>
                    </div>
               </div>
               {dataUsuario2.map((unidad, key) => {
                    
                    if(unidad.infoModulos.modulos !== undefined){
                         let porcentageAvance = 0;
                         unidad.infoModulos.modulos.forEach( modulo => {
                              porcentageAvance = porcentageAvance + modulo.progreso
                         })
                         porcentageAvance = porcentageAvance / (unidad.infoModulos.modulos.length)

                         var archivoReto={fileName: 'archivoMuestra', fileUrl: ''};
                         if(typeof(unidad.infoModulos.informacion.archivoReto) == "string"){
                              try { archivoReto = JSON.parse(unidad.infoModulos.informacion.archivoReto) }catch(e) {}
                         }

                         let evalNumber = 1;

                         let fechaReto = new Date(Date.parse(unidad.infoModulos.configuracion.retoFecha + ' ' + '00:00:00'));
                         let fechaHoy = new Date();

                         let estadoCeldaEstatus = '';
                         let textoCeldaEstatus = '';
                         let iconoCeldaEstatus = <></>;
                         let estatusCeldaReto = '';
                         let textoCeldaReto = '';

                         if(unidad.infoModulos.informacion.fechaRetoGuardado === null){
                              if(fechaReto > fechaHoy){
                                   estadoCeldaEstatus = 'celdaAmarillaTablaRetoJefe';
                                   textoCeldaEstatus = 'En tiempo, aún sin entregar';
                                   estatusCeldaReto= 'linkGrisRetoDetallesJefe';
                                   textoCeldaReto = 'No disponible';
                              }else {
                                   estadoCeldaEstatus = 'celdaRojaTablaRetoJefe';
                                   textoCeldaEstatus = 'No entregado';
                                   iconoCeldaEstatus = <i className="fas fa-times"></i>;
                                   estatusCeldaReto= 'linkRojoRetoDetallesJefe';
                                   textoCeldaReto = 'No hay registro de archivo';
                              }
                         }else {
                              //let fechaRetoUsuario = new Date(Date.parse(unidad.infoModulos.informacion.fechaRetoGuardado + ' ' + '00:00:00'));
                              estadoCeldaEstatus = 'celdaVerdeTablaRetoJefe';
                              textoCeldaEstatus = 'Entregado';
                              iconoCeldaEstatus = <i className="fas fa-check"></i>;
                              estatusCeldaReto= 'linkVerdeRetoDetallesJefe';
                              textoCeldaReto = 'Descargar';
                         }
                         let fechaInicioUnidad = new Date(unidad.infoModulos.configuracion.fechaApertura+"T00:00:00");
                         let fechaEntregaReto = new Date(unidad.infoModulos.configuracion.retoFecha+"T00:00:00");

                         return( 
                              <>
                              <div className="row aire" key={key}>
                                   <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex">{/** RESPONSIVO */}
                                        <div className="col">
                                             <div className="row">
                                                  <div className="col-auto d-flex align-items-center justify-content-start">
                                                       <span className='tituloUnidadDetallesJefe'>Unidad {unidad.orden}: </span>
                                                  </div>
                                                  <div className="col d-flex align-items-center justify-content-start">
                                                       <span className='nombreUnidadDetallesJefe'>{unidad.nombre}</span>
                                                  </div>
                                             </div>
                                             <span> </span>
                                        </div>
                                        <div className="col d-flex align-items-center justify-content-end">
                                             <span className='fechaInicioUnidadDetallesJefe'>Fecha de inicio: {fechaInicioUnidad.getDate() + "/" + meses[fechaInicioUnidad.getMonth()] + "/" + fechaInicioUnidad.getFullYear()}</span>
                                        </div>
                                   </div>
                              </div>
                              <div className="row aire">
                                   <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex">{/** RESPONSIVO */}
                                        <span className='subtitulosDetallesJefe'>Progreso en la Unidad</span>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                                        <table className="table table-bordered">
                                             <thead>
                                                  <tr className='headerTablaModulosJefe'>
                                                       <th className="text-center headerTablasDetallesJefe">% Avance</th>
                                                       {unidad.infoModulos.modulos.map((modulo, key_item) => {
                                                            return (
                                                                 <th className="text-center headerTablasDetallesJefe" key={key_item}>Modulo {modulo.orden}</th>
                                                            )
                                                       })}
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  <tr className='rowTablaModulosJefe'>
                                                       <td className="text-center">
                                                            <div className="col d-flex align-items-center justify-content-center">
                                                                 <Donut diameter={75} min={0} max={100} step={1} value={parseInt(porcentageAvance)} theme={{ donutColor: '#F25836', donutThickness: '5', centerFocusedColor: '#DEDEDE', centerColor: '#DEDEDE', bgrColor: '#D5D7D4' }} ariaLabelledBy={'my-label'}/>
                                                            </div>
                                                       </td>
                                                       {unidad.infoModulos.modulos.map((modulo, key_item) => {
                                                            return (
                                                                 <td className="" key={key_item}>
                                                                      <div className="col d-flex align-items-center justify-content-center paddingCelda">
                                                                           <input type="checkbox" className="form-check-input checkMarkUnidadJefe" defaultChecked={modulo.moduloTerminado}/>
                                                                      </div>
                                                                 </td>
                                                            )
                                                       })}
                                                  </tr>
                                             </tbody>
                                        </table>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex">{/** RESPONSIVO */}
                                        <span className='subtitulosDetallesJefe'>Reto on the Job</span>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                                        <table className="table table-bordered">
                                             <thead>
                                                  <tr className='headerTablaModulosJefe'>
                                                       <th className="text-center headerTablasDetallesJefe">Reto</th>
                                                       <th className="text-center headerTablasDetallesJefe">Fecha de entrega</th>
                                                       <th className="text-center headerTablasDetallesJefe">Estatus</th>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  <tr className='rowTablaModulosJefe'>
                                                       <td className="text-center">
                                                       <Link to={{pathname: archivoReto.fileUrl}} onClick={(e) => {if(archivoReto.fileUrl === ''){ e.preventDefault() } }} target="_blank" className={estatusCeldaReto}>
                                                            {textoCeldaReto}
                                                       </Link>     
                                                       </td>
                                                       <td className="text-center">
                                                            <span className='fechaEntregaRetoUnidadDetallesJefe'>{fechaEntregaReto.getDate() + "/" + meses[fechaEntregaReto.getMonth()] + "/" + fechaEntregaReto.getFullYear()}</span>
                                                       </td>
                                                       <td className={"text-center align-middle " + estadoCeldaEstatus}>
                                                       {iconoCeldaEstatus}&nbsp;&nbsp;{textoCeldaEstatus}
                                                       </td>
                                                  </tr>
                                             </tbody>
                                        </table>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex">{/** RESPONSIVO */}
                                        <span className='subtitulosDetallesJefe'>Resultados Reto on the Job</span>
                                   </div>
                              </div>
                              <div className="row aire">
                                   <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                                        <div className="accordion" id="accordionExample">
                                        {(  typeof unidad.infoModulos.informacion.jsonAutoEvaluacion !== "undefined" && 
                                        unidad.infoModulos.informacion.jsonAutoEvaluacion !== null &&
                                        unidad.infoModulos.informacion.jsonAutoEvaluacion !== '' &&
                                             <ResultadoEvaluacion evaluacion={unidad.infoModulos.informacion.jsonAutoEvaluacion} titulo="AUTOEVALUACIÓN" fechaEvaluacion={unidad.infoModulos.informacion.fechaRevision}/> 
                                        )}
                                        {(
                                        unidad.infoModulos.informacion.resultadosPares.map((parInfo, key_PAR) => {
                                             if( parInfo.jsonEvaluacion === null ){
                                                  return <React.Fragment key={key_PAR}></React.Fragment>
                                             }else{
                                                  return <ResultadoEvaluacion evaluacion={parInfo.jsonEvaluacion} titulo={"EVALUACIÓN " + evalNumber++} fechaEvaluacion={parInfo.fechaRevision}/> 
                                             }
                                        })
                                        )}
                                        </div>
                                   </div>
                              </div>
                                   <div className="row">
                                        <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                                             <div className="accordion acordeonRetroJefe aire" id={`accordionDetallesEvaluacion_` + key}>
                                                  <div className="card cardAcordeonDetallesJefeLearner">
                                                       <div className="card-header" id="headingOne">
                                                            <button className="btn btn-block text-left" type="button" data-toggle="collapse" data-target={`#Collapse_evaluacion` + key} aria-expanded="false" aria-controls={`Collapse_evaluacion` + key}>
                                                                 <div className="row">
                                                                      <div className="col d-flex align-items-center justify-content-center">
                                                                           <span className='tituloAcordeonDetalleJefeLearner'>
                                                                                Revisar evaluación 
                                                                           </span>
                                                                      </div>
                                                                      <div className="col-auto">
                                                                           <div className="d-flex align-items-center justify-content-end">
                                                                                <img src={flechaAcordeon} alt="" className="flechaAcordeon" />
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </button>
                                                       </div>
                                                       <div id={`Collapse_evaluacion` + key} className="collapse resultadosAcordion" aria-labelledby="headingOne" data-parent={`#accordionDetallesEvaluacion_` + key}>
                                                            <div className="card-body cardBodyAcordeonDetallesJefeLearner">
                                                                 {unidad.infoModulos.informacion.evaluacion_compromisos[0].evaluacion_jefe !== "" &&
                                                                      <>
                                                                           <div className="row">
                                                                                <AcordeonEvaluacionJefe formulario={unidad.infoModulos.informacion.evaluacion_compromisos[0].evaluacion_jefe} llave={key}/>
                                                                           </div>
                                                                      </>
                                                                 }
                                                                 {unidad.infoModulos.informacion.evaluacion_compromisos[0].evaluacion_jefe === "" &&
                                                                      <p className='textoNegroParrafosAcordeonesDetallesJefeLearner'>Aún no hay evaluaciónes.</p>
                                                                 }
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>     
                                        </div>
                                   </div>
                                   <div className="row">
                                        <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                                             <div className="accordion acordeonRetroJefe aire" id={`accordionDetallesAcuerdos_` + key}>
                                                  <div className="card cardAcordeonDetallesJefeLearner">
                                                       <div className="card-header" id="headingOne">
                                                            <button className="btn btn-block text-left" type="button" data-toggle="collapse" data-target={`#Collapse_acuerdos` + key} aria-expanded="false" aria-controls={`Collapse_acuerdos` + key}>
                                                                 <div className="row">
                                                                      <div className="col d-flex align-items-center justify-content-center">
                                                                           <span className='tituloAcordeonDetalleJefeLearner'>
                                                                                Revisar Acciones y compromisos
                                                                           </span>
                                                                      </div>
                                                                      <div className="col-auto">
                                                                           <div className="d-flex align-items-center justify-content-end">
                                                                                <img src={flechaAcordeon} alt="" className="flechaAcordeon" />
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </button>
                                                       </div>
                                                       <div id={`Collapse_acuerdos` + key} className="collapse resultadosAcordion" aria-labelledby="headingOne" data-parent={`#accordionDetallesAcuerdos_` + key}>
                                                            <div className="card-body cardBodyAcordeonDetallesJefeLearner">
                                                                 {unidad.infoModulos.informacion.evaluacion_compromisos[0].acuerdos_jefe !== "" &&
                                                                      <>
                                                                           <AcuerdosJefe formulario={unidad.infoModulos.informacion.evaluacion_compromisos[0].acuerdos_jefe}/>
                                                                      </>
                                                                 }
                                                                 {unidad.infoModulos.informacion.evaluacion_compromisos[0].acuerdos_jefe === "" &&
                                                                      <p className='textoNegroParrafosAcordeonesDetallesJefeLearner'>Aún no hay acuerdos.</p>
                                                                 }
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>     
                                        </div>
                                   </div>
                              </>
                         )
                    }
               })}
               <br />
               <br />
               <br />
               <br />
          </>
     )
}
