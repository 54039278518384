import React from 'react'
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import flechaAmarilla from '../../../assets/img/iconos/flechaBlancaRegresar.svg';
import { cargaActividadesModulo } from '../../../modules/services/services';
import './Breadcrum.css'


export default function Breadcrumb() {
     const dispatch = useDispatch();  
     const location = useLocation();
     const unidad  = useSelector((state) => state.modulos);
     const modulo = useSelector((state) => state.actividadesModulo);
     const actividad  = useSelector((state) => state.objetoAprendizaje);
     // const [showRegresar, setShowRegresar] = useState( false );

     let history = useHistory();
     let pathname = window.location.pathname;
     var breadcrumbs = [];

     breadcrumbs.push({label: "Inicio", linkProp: {pathname: '/'} })

     switch (pathname) {
          case '/evaluacionFinal':
               breadcrumbs.push({pre: ``, label:  'Evaluación final', linkProp: {pathname: '/', state:{}} })
               document.title = "LIFT - Lideres del Futuro | Evaluación final"
          break; 
          case '/avisoLogeado':
               breadcrumbs.push({pre: ``, label:  'Aviso de privacidad', linkProp: {pathname: '/', state:{}} })
               document.title = "LIFT - Lideres del Futuro | Aviso de privacidad"
          break; 
          case '/modulos':
               if( !unidad.loading && typeof unidad.unidadActual !== "undefined") {
                    breadcrumbs.push({pre: `UNIDAD DE APRENDIZAJE ${unidad.unidadActual.orden}.`, label:  unidad.unidadActual.nombre, linkProp: {pathname: '/modulos', state:{unidad: unidad.unidadActual.id}} })
                    document.title = `LIFT - Lideres del Futuro | ${unidad.unidadActual.nombre}.`
               }
          break;
          case '/objetos':
               if( !unidad.loading && typeof unidad.unidadActual !== "undefined") {
                    breadcrumbs.push({pre: `UNIDAD DE APRENDIZAJE ${unidad.unidadActual.orden}.`, label: unidad.unidadActual.nombre, linkProp: {pathname: '/modulos', state:{unidad: unidad.unidadActual.id}} })
               }
               if( !modulo.loading && typeof modulo.moduloActual !== "undefined"){
                    breadcrumbs.push({pre: `MÓDULO ${modulo.moduloActual.orden}.`, label: modulo.moduloActual.nombre, linkProp: {pathname: '/objetos', state:{modulo: modulo.moduloActual.id}} })
                    document.title = `LIFT - Lideres del Futuro | ${modulo.moduloActual.nombre}.`
               }
               
          break;
          case '/caso':
               if( !unidad.loading && typeof unidad.unidadActual !== "undefined") {
                    breadcrumbs.push({pre: `UNIDAD DE APRENDIZAJE ${unidad.unidadActual.orden}.`, label: unidad.unidadActual.nombre, linkProp: {pathname: '/modulos', state:{unidad: unidad.unidadActual.id}} })
               }
               breadcrumbs.push({pre: ``, label: "CASO DE ESTUDIO", linkProp: {pathname: '/caso'} })
               document.title = `LIFT - Lideres del Futuro | CASO DE ESTUDIO | ${unidad.unidadActual.nombre}.`
          break;
          case '/reto':
               if( !unidad.loading && typeof unidad.unidadActual !== "undefined") {
                    breadcrumbs.push({pre: `UNIDAD DE APRENDIZAJE ${unidad.unidadActual.orden}.`, label: unidad.unidadActual.nombre, linkProp: {pathname: '/modulos', state:{unidad: unidad.unidadActual.id}} })
               }
               breadcrumbs.push({pre: ``, label: "RETO ON THE JOB", linkProp: {pathname: '/reto'} })
               document.title = `LIFT - Lideres del Futuro | RETO ON THE JOB | ${unidad.unidadActual.nombre}.`
          break;
          case '/encuesta':
               if( !unidad.loading && typeof unidad.unidadActual !== "undefined") {
                    breadcrumbs.push({pre: `UNIDAD DE APRENDIZAJE ${unidad.unidadActual.orden}.`, label: unidad.unidadActual.nombre, linkProp: {pathname: '/modulos', state:{unidad: unidad.unidadActual.id}} })
               }
               breadcrumbs.push({pre: ``, label: "ENCUESTA DE SATISFACCIÓN", linkProp: {pathname: '/reto'} })
               document.title = `LIFT - Lideres del Futuro | ENCUESTA DE SATISFACCIÓN | ${unidad.unidadActual.nombre}.`
          break;
          case '/evaluacion':
               if( !unidad.loading && typeof unidad.unidadActual !== "undefined") {
                    breadcrumbs.push({pre: `UNIDAD DE APRENDIZAJE ${unidad.unidadActual.orden}.`, label: unidad.unidadActual.nombre, linkProp: {pathname: '/modulos', state:{unidad: unidad.unidadActual.id}} })
               }
               breadcrumbs.push({pre: ``, label: "EVALUACIÓN DE LA UNIDAD", linkProp: {pathname: '/reto'} })
               document.title = `LIFT - Lideres del Futuro | EVALUACIÓN DE LA UNIDAD | ${unidad.unidadActual.nombre}.`
          break;
          case '/autoEvaluacion':
               if( !unidad.loading && typeof unidad.unidadActual !== "undefined") {
                    breadcrumbs.push({pre: `UNIDAD DE APRENDIZAJE ${unidad.unidadActual.orden}.`, label: unidad.unidadActual.nombre, linkProp: {pathname: '/modulos', state:{unidad: unidad.unidadActual.id}} })
               }
               breadcrumbs.push({pre: ``, label: "AUTOEVALUACIÓN DE APRENDIZAJES CLAVE", linkProp: {pathname: '/reto'} })
               document.title = `LIFT - Lideres del Futuro | AUTOEVALUACIÓN DE APRENDIZAJES CLAVE | ${unidad.unidadActual.nombre}.`
          break;
          case '/detalleJefe':
               if( !unidad.loading && typeof unidad.unidadActual !== "undefined") {
                    breadcrumbs.push({pre: `UNIDAD DE APRENDIZAJE ${unidad.unidadActual.orden}.`, label: unidad.unidadActual.nombre, linkProp: {pathname: '/modulos', state:{unidad: unidad.unidadActual.id}} })
               }
               breadcrumbs.push({pre: ``, label: "SESIÓN 1-1", linkProp: {pathname: '/detalleJefe'} })
               document.title = `LIFT - Lideres del Futuro | SESIÓN 1-1 | ${unidad.unidadActual.nombre}.`
          break;
          case '/actividades':
               const { moduloActividadId, moduloActividadOrden, moduloActividadNombre } = location.state;
               if( !unidad.loading && typeof unidad.unidadActual !== "undefined") {
                    breadcrumbs.push({pre: `UNIDAD DE APRENDIZAJE ${unidad.unidadActual.orden}.`, label: unidad.unidadActual.nombre, linkProp: {pathname: '/modulos', state:{unidad: unidad.unidadActual.id}} })
               }
               if( !modulo.loading && typeof modulo.moduloActual !== "undefined"){

                    const moduloTemporal = unidad.modulos.filter(function(value, index, arr){ 
                         return value.id === modulo.moduloActual.id;
                    });

                    if(typeof moduloActividadOrden !== 'undefined' && typeof moduloActividadNombre !== 'undefined' && modulo.moduloActual.orden !== moduloActividadOrden && modulo.moduloActual.nombre !== moduloActividadNombre){
                         dispatch( cargaActividadesModulo(unidad.unidadActual.id, moduloActividadId) );
                         breadcrumbs.push({pre: `MÓDULO ${moduloActividadOrden}.`, label: moduloActividadNombre, linkProp: {pathname: '/objetos', state:{modulo: moduloTemporal[0]}} })
                    }else{
                         breadcrumbs.push({pre: `MÓDULO ${modulo.moduloActual.orden}.`, label: modulo.moduloActual.nombre, linkProp: {pathname: '/objetos', state:{modulo: moduloTemporal[0]}} })
                    }
                    
               }
               if( !actividad.loading && typeof actividad.actividad !== "undefined"){
                    breadcrumbs.push({pre: ``, label: actividad.actividad.nombre, linkProp: {pathname: '/actividades', state:{modulo: unidad.unidadActual.id, objeto: actividad.actividad.tipo, id: actividad.actividad.id }} })
               }
               document.title = `LIFT - Lideres del Futuro | ${actividad.actividad.nombre}.`
          break;
          default: 
               document.title = "LIFT - Lideres del Futuro"
          break;
     }

     let regresar = (e) => {
          e.preventDefault();
          if(breadcrumbs.length > 1){
               let br_data = breadcrumbs[breadcrumbs.length-2];
               history.push( br_data.linkProp );
          }
     }

     const separator = <span className="breadcrumbSeparator">&nbsp;|&nbsp;</span>

     return (
          <div className="container-fluid">
               <div className="row rowajuste">
                    <div className="regresar d-flex align-items-center justify-content-end">
                         {pathname !== '/' && (
                              <a href="/#" onClick={ e => regresar(e)} className='breadcrumbItem'>
                                   <img src={flechaAmarilla} alt=""/>
                                   <span className='breadcrumbItem'>&nbsp;&nbsp;&nbsp;REGRESAR</span>
                              </a>
                         )}
                    </div>
                    {/* RESPONSIVO */}
                    <div className="col d-none d-lg-flex align-items-center justify-content-start">
                    {/* FIN RESPONSIVO */}
                    {breadcrumbs.map((breadcrumb, key_item, row) => {
                         return(
                              <React.Fragment key={breadcrumb.label}>
                                   { key_item + 1 < row.length && <Link to={breadcrumb.linkProp} className="breadcrumbItem"><span className='breadcrumbItem'>{breadcrumb.pre}&nbsp;<strong>{breadcrumb.label}</strong></span></Link> }
                                   { key_item + 1 === row.length && <span className='breadcrumbItem'>{breadcrumb.pre}&nbsp;<strong>{breadcrumb.label}</strong></span> }
                                   { key_item + 1 < row.length && <strong style={{margin: '0px 0.5rem'}}>{separator}</strong> }
                              </React.Fragment>
                         )
                    })}
                    </div>
               </div>
          </div>
     )
}
