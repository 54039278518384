import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { CONSTANT } from '../../../static/constants';
import axios from 'axios';
import logo from '../../../assets/img/LogoCedisHeader.svg';
import User from '../../../assets/img/iconos/UserIcon.svg'
import Uploader from '../../../shared/uploader/uploaderPicUsuario';
import InputEspecial from 'components/frontend/elementosVarios/InputEspecial';
import AuthToken from "modules/shared/authToken";
import Notificaciones from '../notificaciones/Notificaciones';
import '../../../views/web/template/Header.css';

export default function Header() {
     const [showCargarImagen, setShowCargarImagen] = useState(false);
     const [showCambiarClave, setShowCambiarClave] = useState(false);
     const dispatch = useDispatch();
     const [passError, setPassError] = useState(null);
     const [passSuccess, setPassSuccess] = useState(null);
     const [btnState, setBtnState] = useState("ACTUALIZAR");
	const currentUser = useSelector((state) => state.auth);
     var obj = { fileName: '', fileUrl: User };
	if (typeof (currentUser.FOTO) == "string") {
		try {
			obj = JSON.parse(currentUser.FOTO)
		} catch (e) { }
	}

     const cerrarSesion = () => {
          dispatch({ type: CONSTANT.AUTH_LOGOUT_RESET_STORE });
          AuthToken.setRemove(true);
     }

     let pass_data = {
          password_0: "",
          password_1: "",
          password_2: ""
     };

     const handleClose = () => {
          setShowCargarImagen(false);
     }

     const handleCloseCambiarClave = () => {
          setShowCambiarClave(false);
          setPassError(null)
          setPassSuccess(null)
     }

     const cambiaForm = target => {
          setPassError(null)
          pass_data = {
               ...pass_data,
               [target.name]: target.value
          };
     }

     const validarPass = (e) => {
          e.preventDefault();
          const valNumeros = /(?=.*[0-9])/;
          const valEspeciales = /(?=.*[!@#$%^&*])/;
          const valMayusculas = /(?=.*[A-Z])/;
          document.getElementById("caracteres").className = (e.target.value.length >= 12) ? 'requisitosPassTrue2' : 'requisitosPassFalse2';
          document.getElementById("numeros").className = valNumeros.test(e.target.value) ? 'requisitosPassTrue2' : 'requisitosPassFalse2';
          document.getElementById("mayusculas").className = valMayusculas.test(e.target.value) ? 'requisitosPassTrue2' : 'requisitosPassFalse2';
          document.getElementById("especiales").className = valEspeciales.test(e.target.value) ? 'requisitosPassTrue2' : 'requisitosPassFalse2';
     }

     let saveData = {};
     const filesUploaded = {}
     const callbackFromChild = (key, data) => {
          filesUploaded[key] = data;
     }

     const saveDataFn = data2Save => {
          const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);
          axios({
               method: 'POST',
               url: process.env.REACT_APP_API_URL + `/api/pic_update`,
               data: data2Save,
               headers: {
                    'Authorization': `${token}`,
                    "Content-Type": "application/json"
               }
          })
               .then(res => {
                    if (res.status === 200) {
                         let tmp = currentUser;
                         tmp.FOTO = (data2Save.foto);
                         dispatch({ type: CONSTANT.AUTH_EMPLOYEE_ID_VERIFICATION_UPDATE_PIC, payload: tmp });
                         handleClose()
                    } else {
                         alert("Ocurrio un error al guardar.");
                    }
               })
               .catch(err => {
                    console.log(err)
                    alert("Ocurrio un error al guardar.");
               });
     }

     const guardaImagen = () => {
          if (Object.keys(filesUploaded).length <= 0) {
               alert('Falta agregar archivo');
               return;
          } else {
               // Subimos archivos a aws
               const key = Object.keys(filesUploaded)[0];
               filesUploaded[key]( saveDataFn, saveData, key )
               delete filesUploaded[key];
          }
     }

     const submit = async e => {
          e.preventDefault()
          if (btnState === "GUARDANDO...") {
               return;
          }
          setBtnState("GUARDANDO...")

          const valNumeros = /(?=.*[0-9])/;
          const valEspeciales = /(?=.*[!@#$%^&*])/;
          const valMayusculas = /(?=.*[A-Z])/;

          if ((pass_data.password_1.length < 12) || !valNumeros.test(pass_data.password_1) || !valMayusculas.test(pass_data.password_1) || !valEspeciales.test(pass_data.password_1)) {
               setBtnState("ACTUALIZAR")
               setPassError('Nueva contraseña no cumple con los requisitos.')
               return;
          }

          //
          setPassError(null)
          if (pass_data.password_1 !== pass_data.password_2) {
               setPassError('Contraseñas no coinciden.')
               return;
          }

          const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);
          axios({
               method: 'POST',
               url: process.env.REACT_APP_API_URL + `/api/password_update`,
               data: pass_data,
               headers: {
                    'Authorization': `${token}`,
                    "Content-Type": "application/json"
               }
          })
               .then(res => {
                    if (res.status === 200) {
                         // console.log("Contraseña actualizada.")
                         setBtnState("ACTUALIZAR")
                         setPassSuccess("Contraseña actualizada con éxito.")
                         handleClose()
                    } else {
                         console.log(res)
                         setPassError('Ocurrio un error al guardar.')
                         setBtnState("ACTUALIZAR")
                    }
               })
               .catch(err => {
                    console.log(err)
                    setPassError('Ocurrio un error al guardar.')
                    setBtnState("ACTUALIZAR")
               });
          // 
     }

     const ModalCambiarImagen = () => {
          return (
               <Modal show={showCargarImagen} onHide={handleClose} animation={false} size='md' centered>
                    <Modal.Title>
                         <div className="row">
                              <div className="d-flex col align-items-center justify-content-end">
                                   <button className="btn botonModal" onClick={handleClose}><i className="far fa-times-circle cerrarModal"></i></button>
                              </div>
                         </div>
                    </Modal.Title>
                    <Modal.Body>
                         <div className='row'>
                              <div className="col uploadBoxBottom">
                                   <div className='uploadBox'>
                                        <div className="col-md-8 offset-md-2">
                                             <div className="d-flex align-items-center justify-content-center">
                                                  <Uploader filesCallback={callbackFromChild} input_key="foto" s3_key={'Usuarios'} input_thumb={obj} accepted_files='image/*' />
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='row'>
                              <div className="col-12">
                                   <div className="d-flex align-items-center justify-content-center">
                                        &nbsp;<br />
                                        <button type="button" style={{ marginTop: '10px' }} onClick={guardaImagen} className="btn btn-verde mt-3">GUARDAR</button>
                                   </div>
                              </div>
                         </div>
                    </Modal.Body>
               </Modal>
          );
     }

     const ModalCambiarClave = () => {
          return (
               <Modal show={showCambiarClave} onHide={handleCloseCambiarClave} animation={false} size='md' centered>
                    <Modal.Title>
                         <div className="row">
                              <div className="d-flex col align-items-center justify-content-end">
                                   <button className="btn botonModal" onClick={handleCloseCambiarClave}><i className="far fa-times-circle cerrarModal"></i></button>
                              </div>
                         </div>
                    </Modal.Title>
                    <Modal.Body>
                         <form onSubmit={submit} method="post">
                              <div className="row">
                                   <InputEspecial nombre="password_0" label="Contraseña actual" funcionSetData={cambiaForm} />
                              </div>
                              <div className="row">
                                   <InputEspecial nombre="password_1" label="Contraseña nueva" funcionSetData={cambiaForm} funcionValidar={validarPass} />
                              </div>
                              <div className="row">
                                   <InputEspecial nombre="password_2" label="Confirmar contraseña nueva" funcionSetData={cambiaForm} />
                              </div>
                              {passError !== null &&
                                   <div className="row aire">
                                        <div class="col d-flex align-items-center justify-content-center">
                                             <small id="passwordHelp" class="text-danger">{passError}</small>
                                             <br />
                                        </div>
                                   </div>
                              }
                              <div className="row aire">
                                   <div className="input-group mb-3">
                                        <ul>
                                             <li id="caracteres" className="requisitosPassFalse2">Al menos 12 caracteres</li>
                                             <li id="mayusculas" className="requisitosPassFalse2">Al menos una may&uacute;scula</li>
                                             <li id="numeros" className="requisitosPassFalse2">Al menos un n&uacute;mero</li>
                                             <li id="especiales" className="requisitosPassFalse2">Al menos un car&aacute;cter especial</li>
                                        </ul>
                                   </div>
                              </div>

                              <div className="row ">
                                   <div className="col d-flex align-items-center justify-content-center">
                                        <button type="submit" className="btn btn-verde" disabled={btnState === "GUARDANDO..." ? true : false} >{btnState}</button>
                                   </div>
                              </div>
                              {passSuccess !== null &&
                                   <div className="row aire">
                                        <div class="col d-flex align-items-center justify-content-center">
                                             <small id="passwordHelp" class="text-success">{passSuccess}</small>
                                             <br />
                                        </div>
                                   </div>
                              }
                         </form>
                    </Modal.Body>
               </Modal>
          );
     }
  return (
     <>
          {showCargarImagen ? <ModalCambiarImagen /> : null}
          {showCambiarClave ? <ModalCambiarClave /> : null}
          <nav id="navbarLift" className="main-header navbar navbar-expand-md navbar-light navbar-white">
               <div className="container-fluid">
                    <div className="logoContainer">
                         <a href="/" className="navbar-brand">
                              <img src={logo} className="logo" alt="logo" />
                         </a>
                    </div>
                    <div className="separador d-none d-sm-none d-md-block"></div>					
                    {/* <span className="nombrePrograma d-none d-sm-none d-md-block">{programa.nombre}</span> */}
                    <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
                         <li className="nav-item ajusteNombreLearner d-none d-lg-block">
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-end">
                                        <span className="navbar-text nombreUsuario text-right">{`${currentUser.NOMBRE} ${currentUser.APELLIDO_P} ${currentUser.APELLIDO_M||''}`}</span>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-end">
                                        <div className="col-auto labelHeaderJefe d-flex align-items-center justify-content-end">
                                             <span className='textoLabelHeaderJefe'>Tutor</span>
                                        </div>
                                   </div>
                              </div>
                         </li>
                         <li className="nav-item">
                              <div class="dropdown">
                                   <img src={obj.fileUrl} className="img-circle fotoPerfil dropdown-toggle" width="60" height="60" alt="Perfil usuario" data-toggle="dropdown" aria-expanded="false"/>
                                   <div class="dropdown-menu">
                                        <a class="dropdown-item" onClick={(e) => setShowCargarImagen(true)}>Cambiar foto</a>
                                        <a class="dropdown-item" onClick={() => { setShowCambiarClave(true) }}>Cambiar contraseña</a>
                                        <a class="dropdown-item" onClick={() => { cerrarSesion() }}>Cerrar sesión</a>
                                   </div>
                              </div>
                         </li>
                         <Notificaciones />
                    </ul>
               </div>
          </nav>
     </>
  )
}
