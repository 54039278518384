import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { CONSTANT } from '../../../static/constants';
import { useAxios } from 'use-axios-client';
import axios from 'axios';
import Select from "react-select";
import { Link } from "react-router-dom";
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import flechaRoja from '../../../assets/img/iconos/flechaRoja.svg';
import flechaGrafica from '../../../assets/img/iconos/iconoFlechaGrafica.svg';
import personaSonido from '../../../assets/img/iconos/iconoPersonaSonido.svg';
import ojo from '../../../assets/img/iconos/iconoOjo.svg';
import engrane from '../../../assets/img/iconos/engrane.svg';
import personaSonidoBlanco from '../../../assets/img/iconos/iconoPersonaSonidoBlanco.svg';
import IconoRetro from '../../../assets/img/iconos/IconoRetroalimentacion.svg';
import IconoGuia from '../../../assets/img/iconos/IconoGuia.svg';
import IconoCompetencia from '../../../assets/img/iconos/IconoCompetencia.svg';
import Descargar from '../../../assets/img/iconos/descargar.svg'

export default function MisHerramientas() {

	const dispatch = useDispatch();
	const storeJefe = useSelector((state) => state.rutaJefe);   
	const [guiasJefe, setGuiasJefe] = useState([])
	const [guiaRetroalimentacion, setGuiaRetroalimentacion] = useState({ fileName: '', fileUrl: '' })

  	const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);

	const {data, error, loading } = useAxios({
		url: process.env.REACT_APP_API_URL + `/api/grupos_jefe`,
		headers: {
			'Authorization': `${token}` ,
			"Content-Type": "application/json",
			'Cache-Control': 'no-cache',
			'Pragma': 'no-cache',
			'Expires': '0'
		}
	})

	if( !data ){
		if(loading) return(<div className="loadingPage"><LoadingSpinner /></div>)
		if(error) return "Error!";
		if(!data) return "no data";
	}

	const options = [];
	data.rows.forEach( function(valor) {
	  	options.push({value: valor.id, label: valor.nombre});
	});

	const updateGrupo = ( data ) => {
		// dispatch({ type: CONSTANT.RUTA_JEFE_SET_GRUPO, payload: data.value })
		requestGuias( data.value )
   	}

	const requestGuias = ( grupo ) => {
		axios({
			method: 'GET',
			url: process.env.REACT_APP_API_URL + `/api/guias_jefe/` + grupo,
			data: {},
			headers: { 
				'Authorization': `${token}`,
				"Content-Type": "application/json",
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'Expires': '0'
			}
		})
		.then(res => { 
			if(res.status===200){
				 setGuiasJefe( res.data )
			}else{
				// alert("Ocurrio un error al guardar.");
			}
		})
		.catch(err => { 
			console.log(err)
			// alert("Ocurrio un error al guardar.");
		});

	}

	return (
		<>
			<div className="row airex2">
				<div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
					<span className='tituloVistaJefe'>MIS HERRAMIENTAS</span>
				</div>
			</div>
			<div className="row aire">
				<div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
					<p className='parradoVistaJefe'>En esta sección encontrarás una serie de herramientas que hemos preparado para ti con la finalidad de facilitar tu labor durante el programa y que puedas brindar un <strong>acompañamiento y retroalimentación de alto valor</strong> para los colaboradores de tu equipo de trabajo.<br /><br />Para cada Unidad de Aprendizaje podrás descargar las siguientes herramientas:</p>
				</div>
			</div>
			<div className="row aire">
				<div className="offset-1 col-10 offset-lg-2 col-lg-8 lineaGrisMisHerramientasJefe">{/** RESPONSIVO */}
					<div className="row">
						<div className="col-auto d-flex align-items-center justify-content-center">
							<img src={IconoGuia} className='iconoMisHerramientasJefe' alt="" width="35" height="35" />
						</div>
						<div className="col-4 col-lg-3 d-flex align-items-center justify-content-start">{/** RESPONSIVO */}
							<span className='textoRojoMisHerramientasJefe'>Guía de Observación</span>
						</div>
						<div className="col-auto d-flex align-items-center justify-content-center">
							<img src={flechaRoja} alt="" width="12" height="11" />
						</div>
						<div className="col paddingIzqJefe">
							<span className='parradoVistaJefe'>Este documento es una guía de apoyo que te proporcionará una serie de comportamientos relacionados con cada competencia que deberás observar en el participante.</span>
						</div>
					</div>
				</div>
			</div>
			<div className="row aire">
				<div className="offset-1 col-10 offset-lg-2 col-lg-8 lineaGrisMisHerramientasJefe">{/** RESPONSIVO */}
					<div className="row">
						<div className="col-auto d-flex align-items-center justify-content-start">
							<img src={IconoCompetencia} className='iconoMisHerramientasJefe' alt="" width="35" height="35" />
						</div>
						<div className="col-4 col-lg-3 d-flex align-items-center justify-content-start">{/** RESPONSIVO */}
							<span className='textoRojoMisHerramientasJefe'>Conoce la Competencia</span>
						</div>
						<div className="col-auto d-flex align-items-center justify-content-center">
							<img src={flechaRoja} alt="" width="12" height="11" />
						</div>
						<div className="col paddingIzqJefe">
							<span className='parradoVistaJefe'>En estas guías rápidas te explicaremos sobre la competencia que estamos buscando desarrollar en el participante  y sus objetivos.</span>
						</div>
					</div>
				</div>
			</div>
			<div className="row aire">
				<div className="offset-1 col-10 offset-lg-2 col-lg-8 lineaGrisMisHerramientasJefe">{/** RESPONSIVO */}
					<div className="row">
						<div className="col-auto d-flex align-items-center justify-content-start">
							<img src={IconoRetro} className='iconoMisHerramientasJefe' alt="" width="35" height="35" />
						</div>
						<div className="col-4 col-lg-3 d-flex align-items-center justify-content-start">{/** RESPONSIVO */}
							<span className='textoRojoMisHerramientasJefe'>Guía de Retroalimentación</span>
						</div>
						<div className="col-auto d-flex align-items-center justify-content-center">
							<img src={flechaRoja} alt="" width="12" height="11" />
						</div>
						<div className="col paddingIzqJefe">
							<span className='parradoVistaJefe'>Adicional a las herramientas de cada unidad, también hemos preparado una guía para ayudarte a estructurar y conducir las sesiones uno a uno de retroalimentación de desempeño que tendrás con los participantes.</span>
						</div>
					</div>
				</div>
			</div>
			<div className="row aire">
				<div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
					<div className="row">
						<div className="col-auto">
							<img src={engrane} className='' alt="" width="41.1" height="41.1" />
						</div>
						<div className="col d-flex align-items-center justify-content-start">
							<span className='parradoVistaJefe'>Haz clic en el material que desees descargar.</span>
						</div>
					</div>
				</div>
			</div>
			<div className="row aire">
				<div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex">{/** RESPONSIVO */}
					<Select name="grupos"
						placeholder="SELECCIONA GENERACIÓN"
						defaultValue={null}
						onChange={updateGrupo}
						options={options}
					/>
				</div>
			</div>
			<div className="row aire">
				<div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex">{/** RESPONSIVO */}
					{Object.keys(guiasJefe).map((idx, key) => {
						let unidad = guiasJefe[idx]
						if( guiaRetroalimentacion.fileName === '' ){
							if (typeof (unidad.guiaRetroalimentacion) == "string") {
								try {
									setGuiaRetroalimentacion( JSON.parse(unidad.guiaRetroalimentacion) )
								} catch (e) { }
							}
						}

						let objGuiaObservacion = { fileName: '', fileUrl: '' }
						if (typeof (unidad.guiaObservacion) == "string") {
							try {
								objGuiaObservacion = JSON.parse(unidad.guiaObservacion) 
							} catch (e) { 
								objGuiaObservacion = { fileName: '', fileUrl: '' }
							}
						}

						let objConoceCompetencia = { fileName: '', fileUrl: '' }
						if (typeof (unidad.conoceCompetencia) == "string") {
							try {
								objConoceCompetencia = JSON.parse(unidad.conoceCompetencia) 
							} catch (e) { 
								objConoceCompetencia = { fileName: '', fileUrl: '' }
							}
						}

						return (
							<div className="col">
								<div className="row">
									<div className="col">
										<div className="col cabezeraMatrizMisHerramientasJefe">
											<div className="row">
												<div className="col d-flex align-items-center justify-content-center">
													<span className='unidadMatrizMisHerramientasJefe'>Unidad de Aprendizaje {unidad.orden+1}</span>
												</div>
											</div>
											<div className="row">
												<div className="col d-flex align-items-center justify-content-center">
													<span className='nombreUnidadMatrizMisHerramientasJefe'>{unidad.unidad_nombre}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Link to={{pathname: objGuiaObservacion.fileUrl}} onClick={(e) => { if(objGuiaObservacion.fileUrl === ''){ e.preventDefault() } }} target="_blank">
											<div className={objGuiaObservacion.fileUrl === '' ? "col herreamientaDeshabilitadaMisHerramientasJefe" : "col herreamientaMisHerramientasJefe"}>
												<div className="row">
													<div className="col-auto d-flex align-items-center justify-content-center">
														<img src={IconoGuia} className='' alt="" width="35" height="35" />
													</div>
													<div className="col">
														<div className="row">
															<div className="col d-flex align-items-center justify-content-start">
																<span className='textoBtnMatrizMisHerramientasJefe'>Guía de Observación</span>
															</div>
														</div>
														<div className="row">
															<div className="col d-flex align-items-center justify-content-start">
																<span className='textoBtnMatrizMisHerramientasJefe'>Unidad {unidad.orden+1}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="row menosAire">
													<div className="offset-4 col-4 d-flex align-items-center justify-content-center">
														<img src={Descargar} className='' alt="" width="17" height="17" />
													</div>
												</div>
											</div>
										</Link>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Link to={{pathname: objConoceCompetencia.fileUrl || ''}} onClick={(e) => { if(objConoceCompetencia.fileUrl === ''){ e.preventDefault() } }} target="_blank">
											<div className={objConoceCompetencia.fileUrl === '' ? "col herreamientaDeshabilitadaMisHerramientasJefe" : "col herreamientaMisHerramientasJefe"}>
												<div className="row">
													<div className="col-auto d-flex align-items-center justify-content-center">
														<img src={IconoCompetencia} className='' alt="" width="35" height="35" />
													</div>
													<div className="col">
														<div className="row">
															<div className="col d-flex align-items-center justify-content-start">
																<span className='textoBtnMatrizMisHerramientasJefe'>Conoce la Competencia</span>
															</div>
														</div>
														<div className="row">
															<div className="col d-flex align-items-center justify-content-start">
																<span className='textoBtnMatrizMisHerramientasJefe'>Unidad {unidad.orden+1}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="row menosAire">
													<div className="offset-4 col-4 d-flex align-items-center justify-content-center">
														<img src={Descargar} className='' alt="" width="17" height="17" />
													</div>
												</div>
											</div>
										</Link>
									</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
			{guiaRetroalimentacion.fileName !== '' && (
			<div className="row">
				<div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex">{/** RESPONSIVO */}
					<div className="col">
						<div className="row">
							<div className="col">
								<Link to={{pathname: guiaRetroalimentacion.fileUrl || ''}} onClick={(e) => { if(guiaRetroalimentacion.fileUrl === ''){ e.preventDefault() } }} target="_blank">
									<div className="col divGrisMatrizMisHerramientas align-items-center justify-content-center">
										<div className="row">
											<div className="offset-4 col-1 d-flex align-items-center justify-content-end">
												<img src={IconoCompetencia} className='' alt="" width="35" height="35" />
											</div>
											<div className="col">
												<div className="row">
													<div className="col d-flex align-items-center justify-content-start">
														<span className='textoBtnMatrizMisHerramientasJefe mt-1'>Guía de Retroalimentación</span>
													</div>
												</div>
											</div>
										</div>
										<div className="row menosAire">
											<div className="offset-4 col-4 d-flex align-items-center justify-content-center">
												<img src={Descargar} className='' alt="" width="17" height="17" />
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			)}
			<br />
			<br />
			<br />
			<br />
		</>
	)
}
