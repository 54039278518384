import { CONSTANT } from '../../static/constants'

const initialState = {
    isAuthenticated: false,
    USERNAME: "",
    NOMBRE:"",
    APELLIDO_P: "",
    APELLIDO_M: "",
    USER_ID: "",
    PROFILE: "",
    FOTO: "",
    BIENVENIDA: "",
    loading: false,
    error: null,
    landingLearner:true,
    landingJefe:true
};

const reducer = (state = initialState, { type, payload }) => {
    switch(type){
        case CONSTANT.AUTH_EMPLOYEE_ID_VERIFICATION_START:
            return {
                ...state,
                error: null,
                USERNAME: payload,
                USER_ID: "",
                PROFILE: "",
                FOTO: "",
                NOMBRE:"",
                APELLIDO_P: "",
                APELLIDO_M: "",
                isAuthenticated: false,
                loading: true,
                landingLearner:true,
                landingJefe:true
              };
        case CONSTANT.AUTH_EMPLOYEE_ID_VERIFICATION_SUCCESS:
            return {
                ...state,
                error: null,
                USER_ID: payload.id,
                PROFILE: payload.perfil,
                NOMBRE: payload.nombre||'',
                APELLIDO_P: payload.apellido_paterno||'',
                APELLIDO_M: payload.apellido_materno||'',
                FOTO: payload.foto,
                BIENVENIDA: payload.bienvenida,
                isAuthenticated: true,
                createdAt: payload.createdAt||'',
                loading: false,
                landingLearner:payload.landingLearner,
                landingJefe:payload.landingJefe
            };
        case CONSTANT.AUTH_EMPLOYEE_ID_VERIFICATION_ERROR:
            return {
                ...state,
                error: payload,
                USER_ID: "",
                PROFILE: "",
                FOTO: "",
                NOMBRE:"",
                APELLIDO_P: "",
                APELLIDO_M: "",
                isAuthenticated: false,
                loading: false,
                landingLearner:true,
                landingJefe:true
            }; 
        case CONSTANT.AUTH_EMPLOYEE_ID_VERIFICATION_UPDATE_PIC:
            return {
                ...state,
                FOTO: payload.FOTO,
            };
        default: 
            return state;
    }
};

export default reducer
  