import React, {useState} from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Slider1 from '../../../assets/img/01_Jefe.png';
import Slider2 from '../../../assets/img/02_Jefe.png';
import Slider3 from '../../../assets/img/03_Jefe.png';
import Slider4 from '../../../assets/img/04_Jefe.png';
import Slider5 from '../../../assets/img/05_Jefe.png';
import Slider6 from '../../../assets/img/06_Jefe.png';
import Slider7 from '../../../assets/img/07_Jefe.png';
import Slider8 from '../../../assets/img/08_Jefe.png';
import Slider9 from '../../../assets/img/09_Jefe.png';
import Slider10 from '../../../assets/img/10_Jefe.png';
import Slider11 from '../../../assets/img/11_Jefe.png';
import Slider12 from '../../../assets/img/12_Jefe.png';
import LandingJefe1 from '../../../assets/img/Landing_jefe1.jpg';
import LandingJefe2 from '../../../assets/img/Landing_jefe2.jpg';
import LandingJefe3 from '../../../assets/img/Landing_jefe3.jpg';
import LandingJefe4 from '../../../assets/img/Check.png';
import LandingJefe5 from '../../../assets/img/Landing_imagenes_calificacion.png';
import LandingJefe6 from '../../../assets/img/Landing_imagenes_reto.png';
import SliderJefe1 from '../../../assets/img/Slider_jefe1.jpg';
import SliderJefe2 from '../../../assets/img/Slider_jefe2.jpg';
import SliderJefe3 from '../../../assets/img/Slider_jefe3.jpg';
import SliderJefe4 from '../../../assets/img/Slider_jefe4.jpg';
import SliderJefe5 from '../../../assets/img/Slider_jefe5.jpg';
import Meta from '../../../assets/img/Meta.png';
import FlechaAcordeonNegra from '../../../assets/img/iconos/FlechaAcordeonNegra.svg'
import FlipCard from './FlipCard';
import '../../../views/web/jefe/jefe.css'

const options = {
     loop: false,
     center: false,
     items: 1,
     nav: true,
     dots: true,
     margin: 35,
     stagePadding: 0,
     autoWidth: false,
     autoHeightClass: false,
     responsiveClass: false,
     mouseDrag: false,
     touchDrag: false,
     navText : ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>']
   };

export default function Introduccion({clickMenu}) {
     const [isFlipped1, setIsFlipped1] = useState(false);
     const [isFlipped2, setIsFlipped2] = useState(false);

     const handleFlip1 = () => {
          setIsFlipped1(!isFlipped1);
        };

     const handleFlip2 = () => {
          setIsFlipped2(!isFlipped2);
     };

  return (
     <>
          <div className="row mt-5">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <span className='tituloVistaJefe'>UN PROGRAMA PARA FORMAR LÍDERES DEL FUTURO</span>
               </div>
          </div>
          <div className="row mt-3">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <p className='textoNegroIntroduccionJefe'>Con <strong>TRACS</strong> buscamos evolucionar la manera en que operamos y para ello tenemos que transformarnos. <br />
                    El <strong>Programa LIFT</strong> brindará a tus colaboradores las herramientas que necesitan para vivir la transformación.</p>
                    <p className='parradoVistaJefe4'>¡Conoce nuestros objetivos y cómo será la experiencia!</p>
               </div>
          </div>
          <div className="row mt-3">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center">{/** RESPONSIVO */}
                    <iframe width="934px" height="526px" src="https://s3.amazonaws.com/tracs.dev/recursos/Video1Jefe.mp4" title="Video de introduccion" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
               </div>
          </div>
          <div className="row mt-5">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <span className='tituloVistaJefe'>CONOCE MÁS DEL PROGRAMA</span>
               </div>
          </div>
          <div className="row mt-3">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <p className='textoNegroIntroduccionJefe'>Las tres unidades de aprendizaje que integran el programa tienen el propósito de <strong>desarrollar diferentes competencias</strong> de los líderes del futuro. <strong>¡Conoce cuáles!</strong></p>
               </div>
          </div>
          <div className="row mt-3">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <div class="accordion" id="accordionExample">
                         <div class="card cardAcordeonIntroJefe">
                              <div class="card-header" id="headingOne">
                                   <button class="btn btn-link btn-acordeonIntroJefe btn-block collapsed text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <div className="row">
                                             <div className="col">
                                                  <h2 class="mb-0 tituloAcordeonIntroJefe">
                                                       Liderazgo del cambio
                                                  </h2>
                                             </div>
                                             <div className="col flechaAcordeonDiv d-flex align-items-center justify-content-end">
                                                  <img src={FlechaAcordeonNegra}  className="flechaAcordeon" alt="" />
                                             </div>
                                        </div>
                                   </button>
                              </div>
                              <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                   <div class="card-body">
                                        <div className="col pl-4 textoAcordeonIntroJefe">
                                             <li className='bullets'>Estrategias para afrontar el cambio.</li>
                                             <li className='bullets'>Resiliencia.</li>
                                             <li className='bullets'>Comunicación efectiva.</li>
                                             <li className='bullets'>Estrategia de motivación.</li>
                                             <li className='bullets'>Cualidades de un líder.</li>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div class="card cardAcordeonIntroJefe">
                         <div class="card-header" id="headingTwo">
                              <button class="btn btn-link btn-block btn-acordeonIntroJefe text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                   <div className="row">
                                        <div className="col">
                                             <h2 class="mb-0 tituloAcordeonIntroJefe">
                                                  Estrategia
                                             </h2>
                                        </div>
                                        <div className="col d-flex flechaAcordeonDiv align-items-center justify-content-end">
                                             <img src={FlechaAcordeonNegra}  className="flechaAcordeon" alt="" />
                                        </div>
                                   </div>
                              </button>
                         </div>
                         <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                              <div class="card-body">
                                   <div className="col pl-4 textoAcordeonIntroJefe">
                                        <li className='bullets'>Yo, el tomador de decisiones.</li>
                                        <li className='bullets'>Mi pensamiento estratégico.</li>
                                        <li className='bullets'>Mi enfoque innovador.</li>
                                        <li className='bullets'>Mi perspicacia financiera.</li>
                                   </div>
                              </div>
                         </div>
                         </div>
                         <div class="card cardAcordeonIntroJefe">
                         <div class="card-header" id="headingThree">
                              <button class="btn btn-link btn-block btn-acordeonIntroJefe text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                   <div className="row">
                                        <div className="col">
                                             <h2 class="mb-0 tituloAcordeonIntroJefe">
                                                  Gestión
                                             </h2>
                                        </div>
                                        <div className="col flechaAcordeonDiv d-flex align-items-center justify-content-end">
                                             <img src={FlechaAcordeonNegra}  className="flechaAcordeon" alt="" />
                                        </div>
                                   </div>
                              </button>
                         </div>
                         <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                              <div class="card-body">
                                   <div className="col pl-4 textoAcordeonIntroJefe">
                                        <li className='bullets'>Ser un líder valiente.</li>
                                        <li className='bullets'>Asegurar la ejecución.</li>
                                        <li className='bullets'>Dirigir resultados.</li>
                                        <li className='bullets'>Enfoque en los clientes.</li>
                                   </div>
                              </div>
                         </div>
                         </div>
                    </div>
               </div>
          </div>
          <div className="row mt-5">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <p className='textoNegroIntroduccionJefe'>A lo largo de los <strong>módulos</strong> que integran las diferentes unidades, tus colaboradores consultarán los <strong>objetos de aprendizaje</strong> que hemos desarrollado para el programa. Al finalizar cada unidad también vivirán <strong>tres actividades muy importantes.</strong> Aquí te contamos cuáles son:</p>
               </div>
          </div>
          <div className="row">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">
                    <div className="row">
                         <div className="col">
                              <div className="row">
                                   <div className="col">
                                        <img src={LandingJefe1} alt="" className='img-fluid'/>
                                   </div>
                              </div>
                              <div className="row mt-3">
                                   <div className="col">
                                        <span className='tituloAcordeonIntroJefe'>CASO DE ESTUDIO</span> <br />
                                        <p className='parradoVistaJefe'>Es un caso o situación creada por especialistas de OXXO.</p>
                                   </div>
                              </div>
                         </div>
                         <div className="col">
                              <div className="row">
                                   <div className="col">
                                        <img src={LandingJefe2} alt="" className='img-fluid'/>
                                   </div>
                              </div>
                              <div className="row mt-3">
                                   <div className="col">
                                        <span className='tituloAcordeonIntroJefe'>SESIÓN LIVE</span> <br />
                                        <p className='parradoVistaJefe'>Reunión en grupo para discutir la solución al caso de estudio.</p>
                                   </div>
                              </div>
                         </div>
                         <div className="col">
                              <div className="row">
                                   <div className="col">
                                        <img src={LandingJefe3} alt="" className='img-fluid'/>
                                   </div>
                              </div>
                              <div className="row mt-3">
                                   <div className="col">
                                        <span className='tituloAcordeonIntroJefe'>RETO ON THE JOB</span> <br />
                                        <p className='parradoVistaJefe'>Actividad práctica individual orientada a su día a día en la que deben aplicar sus conocimientos y aprendizajes.</p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <div className="row fondoGradianteLandingJefe mt-4">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">
                    <div className="row mt-5">
                         <div className="col">
                              <p className='parradoVistaJefe'>Para acreditar el programa los colaboradores inscritos deben:</p>
                         </div>
                    </div>
                    <div className="row">
                         <div className="col">
                              <div className="row">
                                   <div className="col d-flex justify-content-center">
                                        <img src={LandingJefe4} alt="" width={137}/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col">
                                        <p className='parradoVistaJefe2'>Completar todas las unidades de aprendizaje</p>
                                   </div>
                              </div>
                         </div>
                         <div className="col">
                              <div className="row">
                                   <div className="col d-flex justify-content-center">
                                        <img src={LandingJefe6} alt="" width={137}/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col">
                                        <p className='parradoVistaJefe2'>Aprobar los tres Retos on the Job, esto equivale al 60% de su calificación.</p>
                                   </div>
                              </div>
                         </div>
                         <div className="col">
                              <div className="row">
                                   <div className="col d-flex justify-content-center">
                                        <img src={LandingJefe5} alt="" width={137}/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col">
                                        <p className='parradoVistaJefe2'>Obtener una calificación mayor a 8 en su evaluación final, que corresponde al 40% restante.</p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <div className="rows mt-5">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <span className='tituloVistaJefe'>TU PAPEL ES CLAVE</span>
               </div>
          </div>
          <div className="row mt-3">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <p className='textoNegroIntroduccionJefe'><strong>Tu participación es muy importante</strong> para asegurar el crecimiento de tus colaboradores en este programa. Tu involucramiento se divide en las siguientes actividades:</p>
               </div>
          </div>
          <div className="row mt-3">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">
                    <OwlCarousel className="owl-theme" {...options}>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center">
                                        <img src={SliderJefe1}/>
                                   </div>
                              </div>
                              <div className="row paddingLabelCarousel">
                                   <div className="col espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-start mb-3">
                                             <span className='textoSliderCarrousellLanding'>Da <strong>seguimiento</strong> a tus colaboradores inscritos al programa LIFT preguntándoles si tienen dudas o requieren algún tipo de apoyo de tu parte, que se sientan respaldados por ti.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center">
                                        <img src={SliderJefe2}/>
                                   </div>
                              </div>
                              <div className="row paddingLabelCarousel">
                                   <div className="col espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-start mb-3">
                                             <span className='textoSliderCarrousellLanding'>Da <strong>seguimiento al Reto on the Job</strong> de tus colaboradores para que observes sus avances y cuentes con más argumentos para la posterior retroalimentación.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center">
                                        <img src={SliderJefe3}/>
                                   </div>
                              </div>
                              <div className="row paddingLabelCarousel">
                                   <div className="col espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-start mb-3">
                                             <span className='textoSliderCarrousellLanding'><strong>Retroalimenta</strong> a tus colaboradores sobre los comportamientos que observaste, así como tus recomendaciones para seguir perfeccionando sus competencias.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center">
                                        <img src={SliderJefe4}/>
                                   </div>
                              </div>
                              <div className="row paddingLabelCarousel">
                                   <div className="col espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-start mb-3">
                                             <span className='textoSliderCarrousellLanding'><strong>Motiva</strong> a tus colaboradores para acumular insignias y apóyalos para realizar sus actividades en las fechas establecidas, ya que esto es un elemento clave para avanzar en su ruta de aprendizaje.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center">
                                        <img src={SliderJefe5}/>
                                   </div>
                              </div>
                              <div className="row paddingLabelCarousel">
                                   <div className="col espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-start mb-3">
                                             <span className='textoSliderCarrousellLanding'><strong>Tu acompañamiento es clave</strong> para que tus colaboradores se autodesarrollen con este programa y sean los Líderes del Futuro que necesitamos.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </ OwlCarousel>
               </div>
          </div>
          <div className="rows mt-5">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <span className='tituloVistaJefe'>RETROALIMENTA EN LIFT</span>
               </div>
          </div>
          <div className="row mt-3">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <p className='textoNegroIntroduccionJefe'>Dentro de la plataforma LIFT podrás llevar a cabo tus actividades <strong>de retroalimentación a participantes.</strong> Aquí te contamos todo lo que necesitas saber sobre ella.</p>
               </div>
          </div>
          <div className="row mt-3">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center">{/** RESPONSIVO */}
                    <iframe width="934px" height="526px" src="https://s3.amazonaws.com/tracs.dev/recursos/Video2jefe.mp4" title="Video de introduccion" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
               </div>
          </div>
          <div className="row fondoGradianteLandingJefe mt-4">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">
                    <div className="row mt-5">
                         <div className="col">
                              <p className='parradoVistaJefe3'>Ahora que conoces las actividades que realizarás en LIFT, te explicamos con más detalle cómo llevarlas a cabo:</p>
                         </div>
                    </div>
                    <div className="row rowGrisIntroduccionJefe">
                         <div className="col-1 d-flex align-items-center justify-content-center">
                              <i className='fas fa-eye iconoIntroduccion'></i>
                         </div>
                         <div className="col">
                              <div className="row">
                                   <div className="col">
                                        <span className='textoRojoIntroduccionJefe'>Observa</span>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col">
                                        <span className='textoNegroIntroduccionJefe'>Revisa con detenimiento el desempeño de cada uno de tus colaboradores, observa sus comportamientos y la forma en que se desenvuelve en el día a día. Apóyate en la <strong>"Guía de Observación"</strong> que hemos preparado para ti, ubicada en la sección <strong>"Mis herramientas"</strong>.</span>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div className="row rowGrisIntroduccionJefe mt-3">
                         <div className="col-1 d-flex align-items-center justify-content-center">
                              <i className='fas fa-user-friends iconoIntroduccion'></i>
                         </div>
                         <div className="col">
                              <div className="row">
                                   <div className="col">
                                        <span className='textoRojoIntroduccionJefe'>Retroalimenta</span>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col">
                                        <span className='textoNegroIntroduccionJefe'>Reúnete con el colaborador y retroaliméntalo, brindándole recomendaciones para seguir mejorando. Utiliza la <strong>"Guía de retroalimentación"</strong>, que encontrarás en <strong>"Mis herramientas"</strong>, 1para aprovechar al máximo este momento.</span>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div className="row mt-5">
                         <div className="col-5 d-flex justify-content-center">
                              <img src={Meta} alt="" width={336}/>
                         </div>
                         <div className="col-7 d-flex justify-content-center align-items-center">
                              <p className='textoBlancoIntroduccionJefe'>La calificación que asignes a tus colaboradores <strong>no será parte de su calificación</strong> del programa sin embargo, es muy importante pues es un <strong>indicativo de la aplicación de las competencias</strong> desarrolladas en sus actividades diarias.</p>
                         </div>
                    </div>
                    <div className="row mt-5">
                         <div className="col">
                              <span className='tituloVistaJefe'>RECOMENDACIONES PARA EL SEGUIMIENTO</span>
                         </div>
                    </div>
                    <div className="row mt-5 mb-1">
                         <div className="col">
                              <p className='parradoVistaJefe'>Te compartimos algunas recomendaciones que te ayudarán con tu participación en el programa LIFT.</p>
                         </div>
                    </div>
                    <div className="row mb-5">
                         <div className="col d-flex justify-content-center">
                              <FlipCard 
                                   front={
                                        <div className='flipcard1 d-flex align-items-end justify-content-center'>
                                             <button className='btnLandingJefe' onClick={handleFlip1}>EN PLATAFORMA</button>
                                        </div>
                                   }
                                   back={
                                        <>
                                             <div className="row">
                                                  <div className="col">
                                                       <span className='tituloFlipCard'>EN PLATAFORMA</span>
                                                  </div>
                                             </div>
                                             <div className="row mt-2">
                                                  <div className="col">
                                                       <ul className='puntosFlipCard'>
                                                            <li>Mantente atento a tus actividades en el programa LIFT.</li>
                                                            <li>Establece acciones o acuerdos que te faciliten dar seguimiento.</li>
                                                            <li>Consulta el avance de tu equipo en cada unidad.</li>
                                                            <li>Revisa las fechas establecidas para dar seguimiento a tu equipo.</li>
                                                            <li>Brinda una retroalimentación oportuna y concreta que permita al colaborador identificar áreas de oportunidad y fortalezas para obtener un mejor desempeño en sus actividades como líder.</li>
                                                       </ul>
                                                  </div>
                                             </div>
                                             <div className="row">
                                                  <div className="col d-flex justify-content-center">
                                                       <button className='btn' onClick={handleFlip1}><i className="fas fa-chevron-left"></i></button>
                                                  </div>
                                             </div>
                                        </>
                                   }
                                   isFlipped={isFlipped1}
                              ></FlipCard>
                         </div>
                         <div className="col d-flex justify-content-center">
                              <FlipCard 
                                   front={
                                        <div className='flipcard2 d-flex align-items-end justify-content-center'>
                                             <button className='btnLandingJefe' onClick={handleFlip2}>CON MI COLABORADOR</button>
                                        </div>
                                   }
                                   back={
                                        <>
                                             <div className="row">
                                                  <div className="col">
                                                       <span className='tituloFlipCard'>CON MI COLABORADOR</span>
                                                  </div>
                                             </div>
                                             <div className="row mt-2">
                                                  <div className="col">
                                                       <ul className='puntosFlipCard'>
                                                            <li>¿Cómo te has sentido al cursar el programa LIFT?</li>
                                                            <li>¿Has tenido alguna limitante que impida tu avance?</li>
                                                            <li>¿Requieres algún apoyo de mi parte?</li>
                                                            <li>De los módulos que has cursado, ¿cuál has encontrado de mayor valor para ti?</li>
                                                            <li>Al aplicar las herramientas que se te proporcionan en el programa LIFT, ¿qué has descubierto?, ¿te facilitan la gestión y/o dirección de tus actividades con el equipo o administrativas?</li>
                                                       </ul>
                                                  </div>
                                             </div>
                                             <div className="row">
                                                  <div className="col d-flex justify-content-center">
                                                       <button className='btn' onClick={handleFlip2}><i className="fas fa-chevron-left"></i></button>
                                                  </div>
                                             </div>
                                        </>
                                   }
                                   isFlipped={isFlipped2}
                              ></FlipCard>
                         </div>
                    </div>
               </div>
          </div>
          <div className="rows mt-5">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">{/** RESPONSIVO */}
                    <span className='tituloVistaJefe'>¿CÓMO UTILIZAR LIFT?</span>
               </div>
          </div>
          <div className="row mt-3">
               <div className="offset-1 col-10 offset-lg-2 col-lg-8">
                    <OwlCarousel className="owl-theme" {...options}>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider1} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellLanding'>1 / 12.  <strong>TOUR.</strong> Inicia este pequeño tutorial de 1 minuto para tener una visión general de esta interfaz.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider2} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellLanding'>2 / 12. <strong> INTRODUCCIÓN.</strong> Haz clic en este apartado siempre que quieras dirigirte a la página de inicio.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider3} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellLanding'>3 / 12.  <strong>MIS EQUIPOS.</strong> En esta sección aparecen todos tus colaboradores que estén inscritos al programa. Consulta su avance en el programa.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider4} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellLanding'>4 / 12. <strong>MIS EQUIPOS.</strong> Si el colaborador ha cumplido con su reto de manera puntual, su progreso se marcará "En tiempo". Si por el contrario, tiene el reto pendiente, su estatus será "Retraso".</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider5} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellLanding'>5 / 12. <strong>MIS EQUIPOS.</strong> Para conocer el detalle de las actividades y pendientes que el colaborador tiene asignados, haz clic en el botón Ver detalles.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider6} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellLanding'>6/12. El <strong>detalle de cada colaborador está organizado</strong> por unidad y muestra el progreso, el Reto on the Job y los Resultados del Reto on the Job que tenga disponibles.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider7} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellLanding'>7 / 12. Una vez finalizada tu observación, haz clic en "Prepara evaluación" para subir tus comentarios y sugerencias.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider8} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellLanding'>8 / 12.  <strong>En este apartado guarda tu retroalimentación.</strong> Para ello, deberás evaluar al colaborador, con base en las preguntas y afirmaciones que se presenten.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider9} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellLanding'>9 / 12. Cuando termines de registrar tu retroalimentación, podrás guardarla. Ahora, estás listo para retroalimentar al colaborador en la sesión programada para ello.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider10} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellLanding'>10 / 12. Si en la sesión con el colaborador decidiste cambiar alguna de tus observaciones, podrás hacerlo cambiando el reactivo. Cuando estés seguro de tu retroalimentación haz clic en "Enviar".</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider11} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellLanding'>11 / 12. Enseguida, registra las acciones y compromisos que tu colaborador debe llevar a cabo para mejorar su rendimiento. Una vez que hayas terminado, haz clic en "Terminar retroalimentación" para que el colaborador las pueda recibir y consultar en cualquier momento.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className='item'>
                              <div className="row">
                                   <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionJefe">
                                        <img src={Slider12} className='sliderCorrousellIntroduccionJefe'/>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col d-flex align-items-start justify-content-center espacioGradianteLandingCarousel">
                                        <div className="offset-1 col-10 d-flex align-items-start justify-content-center">
                                             <span className='textoSliderCarrousellLanding'>12/ 12. Mis herramientas. Aquí podrás descargar los materiales que hemos preparado para ti, los cuales te ayudarán a observar y retroalimentar al colaborador. Selecciona la generación en la que estás trabajando y después haz clic en el material que desees descargar.</span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </OwlCarousel>
               </div>
          </div>
          <div className="row mt-5">
              <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center">{/** RESPONSIVO */}
                   <span className='tituloVistaJefe justificadoCentro'>¡Eres pieza clave en el desarrollo de tus colaboradores, gracias por acompañarlos en esta experiencia!</span>
              </div>
          </div>
          <div className="row mt-5">
              <div className="offset-1 col-10 offset-lg-2 col-lg-8 d-flex align-items-center justify-content-center">{/** RESPONSIVO */}
                   <button className='btnLandingLearner2' onClick={(e) => clickMenu(e, 'dos')}>CONTINUAR CON MIS EQUIPOS</button>
              </div>
          </div>
          <br />
          <br />
          <br />
          <br />
     </>
  )
}
