import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import { CONSTANT } from "../../../static/constants";
import { useForm } from "react-hook-form";
import LogoCEDIS from '../../../assets/img/LogoCEDIS.svg'
import LogoAcademia from '../../../assets/img/LogoAcademiaOXXO.png'
import Slider1 from '../../../assets/img/Slider1.jpg'
import Slider2 from '../../../assets/img/Slider2.jpg'
import Slider3 from '../../../assets/img/Slider3.jpg'
import Slider4 from '../../../assets/img/Slider4.jpg'
import Slider5 from '../../../assets/img/Slider5.jpg'
import Slider6 from '../../../assets/img/Slider6.jpg'
import ImgBandera from '../../../assets/img/ImgBanderaLandingLearner.svg'
import DiagramaUnidad from '../../../assets/img/DiagramaUnidad.svg'
import DiagramaUnidadMovil from '../../../assets/img/DiagramaUnidadMovil.svg'
import IconoDescargar from '../../../assets/img/iconos/descargar.svg'
import OwlCarousel from 'react-owl-carousel';
import IconoHome from '../../../assets/img/iconos/IconoHome2.svg'
import GuiaPart from '../../../assets/docs/LiftCEDIS_GuíaDelParticipante.pdf'
import './landingLearner.css'

const options = {
  loop: false,
  center: false,
  items: 1,
  nav: true,
  dots: true,
  margin: 35,
  stagePadding: 0,
  autoWidth: false,
  autoHeightClass: false,
  responsiveClass: false,
  mouseDrag: false,
  touchDrag: false,
  navText : ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>']
};

const RenderTemplateLanding = () => {

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth);
  const { register, handleSubmit } = useForm();
  const [checked, setChecked] = useState(false);
  const [screenSize, setScreenSize] = useState(null);


  useEffect(() => {
    function handleScreenSize() {
      const width = window.innerWidth;
      if (width <= 768) {
        setScreenSize('pequena');
      } else if (width >= 769) {
        setScreenSize('grande');
      }
    }
    window.addEventListener('resize', handleScreenSize);
    handleScreenSize();
    return () => {
      window.removeEventListener('resize', handleScreenSize);
    };
  }, []);

  const handleOnChange = () => {
    setChecked(!checked);
  };

  const handleDescargarGuia = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = GuiaPart;
    downloadLink.download = 'GuiaParticipante.pdf'; // Puedes cambiar el nombre del archivo si es necesario
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
  }

  const onSubmit = datos => {
    let cu = JSON.parse(JSON.stringify(currentUser));
    cu.landingLearner=false;
    const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);
    if (datos.check) {
      axios({
        method: 'PUT',
        url: process.env.REACT_APP_API_URL + `/api/desactiva_landing_learner`,
        data: JSON.stringify({}),
        headers: {
            'Authorization': `${token}`,
            "Content-Type": "application/json"
        }
      })
      .then(res => {
          if (res.status === 200) {
              dispatch({ type: CONSTANT.AUTH_EMPLOYEE_ID_VERIFICATION_SUCCESS, payload: {id: cu.USER_ID, perfil: cu.PROFILE, foto: cu.FOTO, nombre: cu.NOMBRE, apellido_paterno: cu.APELLIDO_P, apellido_materno: cu.APELLIDO_M, cratedAt: cu.createdAt, landingLearner: cu.landingLearner, landingJefe: cu.landingJefe} });
              localStorage.setItem(CONSTANT.AUTH_LANDING_LEARNER, false);
          } else {
              console.log("Error al desactivar Bienvenida.")
          }
      })
      .catch(err => {
          console.log(err)
      });
    }else{
      dispatch({ type: CONSTANT.AUTH_EMPLOYEE_ID_VERIFICATION_SUCCESS, payload: {id: cu.USER_ID, perfil: cu.PROFILE, foto: cu.FOTO, nombre: cu.NOMBRE, apellido_paterno: cu.APELLIDO_P, apellido_materno: cu.APELLIDO_M, cratedAt: cu.createdAt, landingLearner: cu.landingLearner, landingJefe: cu.landingJefe} });
      localStorage.setItem(CONSTANT.AUTH_LANDING_LEARNER, false);
    }
  }

  return (
    <>
      <div className="row fondoGradianteLandingHeader">
        <div className="col d-flex justify-content-center align-items-center">
          <img src={LogoCEDIS} alt="" className="p-4"/>
        </div>
        <div className="col d-flex justify-content-center align-items-center">
          <img src={LogoAcademia} alt="" className="p-4" width={272}/>
        </div>
      </div>
      <div className="row paddingLanding">
        <div className="container">
          <div className="row">
            <div className="col mt-5">
              <span className="tituloLanding">¡SÉ UN LÍDER DEL FUTURO!</span>
            </div>
          </div>
          <div className="row">
            <div className="col mt-4">
              <p className="textoLandingLearner4">Con <strong>TRACS</strong> buscamos llegar a un nivel de servicio más competitivo y tú eres parte fundamental para lograrlo.
              <br />El <strong>Programa LIFT</strong> te brindará las herramientas que necesitas para vivir la transformación.</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="textoLandingLearner1"><strong>¡Conoce nuestros objetivos y cómo será la experiencia!</strong></p>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex align-items-center justify-content-center">
              <iframe width="934px" height="526px" src="https://s3.amazonaws.com/tracs.dev/recursos/Video1.mp4" title="Video de introduccion" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
            </div>
          </div>
          <div className="row">
            <div className="col mt-5">
              <span className="tituloLanding">VIVE LA EXPERIENCIA DEL VIAJE</span>
            </div>
          </div>
          <div className="row">
            <div className="col mt-3">
              <p className="textoLandingLearner4">Ahora que ya comienzas tu experiencia en la <strong>plataforma LIFT</strong> para revisar los contenidos y realizar las actividades que integran el programa, <strong>te compartimos un ejemplo de cómo se despliega el programa:</strong></p>
            </div>
          </div>
          <div className="row">
            <div className="col">
                <img src={screenSize === 'grande' ? DiagramaUnidad : DiagramaUnidadMovil} alt="Diagrama Unidad" style={{width: '100%', height: 'auto'}}/>
            </div>
          </div>
          <div className="row">
            <div className="col mt-5">
              <p className="textoLandingLearner4">Te explicamos más sobre los tiempos para realizar tus actividades:</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
            <OwlCarousel className="owl-theme" {...options}>
              <div className='item'>
                  <div className="row">
                        <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionLearner">
                            <img src={Slider1} className='sliderCorrousellIntroduccionLearner'/>
                        </div>
                  </div>
                  <div className="row paddingLabelCarousel">
                        <div className="col espacioGradianteLandingCarousel">
                            <div className="offset-1 col-10 d-flex align-items-start justify-content-start">
                                  <span className='textoSliderCarrousellLanding'>Tendrás <strong>una semana para</strong> consultar el contenido de <strong>cada módulo.</strong></span>
                            </div>
                        </div>
                  </div>
              </div>
              <div className='item'>
                  <div className="row">
                        <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionLearner">
                            <img src={Slider2} className='sliderCorrousellIntroduccionLearner'/>
                        </div>
                  </div>
                  <div className="row paddingLabelCarousel">
                        <div className="col espacioGradianteLandingCarousel">
                            <div className="offset-1 col-10 d-flex align-items-start justify-content-start mb-3">
                                  <span className='textoSliderCarrousellLanding'>Las tres semanas posteriores a la revisión de los módulos deberás:
                                  <li className="bulletCarrouselLanding">Analizar el Caso de Estudio.</li>
                                  <li className="bulletCarrouselLanding">Asistir a la Sesión Live.</li>
                                  <li className="bulletCarrouselLanding">Realizar el Reto <i>on the Job</i>.</li></span>
                            </div>
                        </div>
                  </div>
              </div>
              <div className='item'>
                  <div className="row">
                        <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionLearner">
                            <img src={Slider3} className='sliderCorrousellIntroduccionLearner'/>
                        </div>
                  </div>
                  <div className="row paddingLabelCarousel">
                        <div className="col espacioGradianteLandingCarousel">
                            <div className="offset-1 col-10 d-flex align-items-start justify-content-start">
                                  <span className='textoSliderCarrousellLanding'>Considera que el <strong>Reto <i>on the Job</i></strong> deberás realizarlo después de analizar el caso de estudio y cuentas con <strong>dos semanas para entregarlo.</strong></span>
                            </div>
                        </div>
                  </div>
              </div>
              <div className='item'>
                  <div className="row">
                        <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionLearner">
                            <img src={Slider4} className='sliderCorrousellIntroduccionLearner'/>
                        </div>
                  </div>
                  <div className="row paddingLabelCarousel">
                        <div className="col espacioGradianteLandingCarousel">
                            <div className="offset-1 col-10 d-flex align-items-start justify-content-start">
                                  <span className='textoSliderCarrousellLanding'>Posteriormente, tendrás <strong>una semana de descanso.</strong> Durante esta semana <strong>tu Jefe iniciará la observación</strong> del desarrollo y aplicación de competencias y comportamientos.</span>
                            </div>
                        </div>
                  </div>
              </div>
              <div className='item'>
                  <div className="row">
                        <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionLearner">
                            <img src={Slider5} className='sliderCorrousellIntroduccionLearner'/>
                        </div>
                  </div>
                  <div className="row paddingLabelCarousel">
                        <div className="col espacioGradianteLandingCarousel">
                            <div className="offset-1 col-10 d-flex align-items-start justify-content-start">
                                  <span className='textoSliderCarrousellLanding'>Este periodo de observación dura <strong>tres semanas</strong> y en las siguientes <strong>dos semanas</strong> deberás tener una sesión de retroalimentación con él.
                                  <strong> Esta actividad no es parte de tu calificación pero te permitirá corroborar tu aprendizaje.</strong></span>
                            </div>
                        </div>
                  </div>
              </div>
              <div className='item'>
                  <div className="row">
                        <div className="col d-flex align-items-center justify-content-center espacioNegroCarouselIntroduccionLearner">
                            <img src={Slider6} className='sliderCorrousellIntroduccionLearner'/>
                        </div>
                  </div>
                  <div className="row paddingLabelCarousel">
                        <div className="col espacioGradianteLandingCarousel">
                            <div className="offset-1 col-10 d-flex align-items-start justify-content-start">
                                  <span className='textoSliderCarrousellLanding'>Después de la semana de descanso, iniciarás la consulta del contenido de la <strong>siguiente unidad.</strong></span>
                            </div>
                        </div>
                  </div>
              </div>
              </ OwlCarousel>
            </div>
          </div>
          <div className="row">
            <div className="col mt-4 d-flex justify-content-center align-items-center">
              <span className="textoLandingLearner2">Estas actividades las repetirás en cada una de las Unidades de aprendizaje.</span>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center align-items-center mb-5">
              <span className="textoLandingLearner3"><strong>¡Pon todo tu esfuerzo!</strong></span>
            </div>
          </div>
        </div>
      </div>
      <div className="row fondoGradianteLandingBody paddingLanding">
        <div className="container">
          <div className="row">
            <div className="col mt-5">
              <span className="tituloLanding">APROVECHA LA EXPERIENCIA LIFT... ¡AL MÁXIMO!</span>
            </div>
          </div>
          <div className="row">
            <div className="col mt-3">
              <p className="textoLandingLearner4">Para aprobar el programa debes cumplir ciertos criterios. Pero LIFT no es solo para aprobarse, ¡es para vivirlo!</p>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center align-items-center mt-3">
              <p className="textoLandingLearner3"><strong>Reproduce el video y conoce cómo vivir al máximo esta experiencia.</strong></p>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex align-items-center justify-content-center">
              <iframe width="934px" height="526px" src="https://s3.amazonaws.com/tracs.dev/recursos/Video2.mp4" title="Video de introduccion" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
            </div>
          </div>
          <div className="row">
            <div className="col mt-4">
              <span className="tituloLanding">TODA LA INFORMACIÓN A TU ALCANCE</span>
            </div>
          </div>
          <div className="row">
            <div className="col mt-2">
              <p className="textoLandingLearner4">Tenemos para ti dos <strong>guías</strong> que te serán de gran ayuda para entender mejor la dinámica del Programa LIFT.</p>
            </div>
          </div>
          <div className="row">
            <div className="col p-2">
              <button className="btnLandingLearner1" onClick={handleDescargarGuia}>
                <span>Descargar <br />Guía del participante<br /><img src={IconoDescargar} alt="" /></span>
              </button>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-12 col-md p-2">
              <img src={ImgBandera} alt="" style={{width: '100%', height: 'auto'}}/>
            </div>
            <div className="col p-2">
              <div className="row">
                <div className="col-sm-12 col-md">
                  <p className="tituloLanding"><strong>“LA TAREA DEL LÍDER ES LLEVAR A LA GENTE DESDE DONDE ESTÁN HASTA DONDE NO HAN ESTADO”</strong></p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md">
                  <span className="textoLandingLearner4"><strong>Henry Kissinger</strong></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center align-items-center mt-5">
              <span className="tituloLanding">¿Estás listo para desarrollarte al máximo?</span>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col d-flex align-items-center justify-content-center mt-4">
                <button type="submit" className="btnLandingLearner2">
                  <span>¡SÍ, COMENZAR YA!</span>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col d-flex align-items-center justify-content-center mt-2">
                <div className="form-check">
                    <input {...register('check', { required: false })} className="form-check-input checkMarkLandingLearner" name={'check'} type="checkbox" checked={checked} onChange={handleOnChange} id='checkLandingLearner'/>
                    <label className="form-check-label" htmlFor="checkLandingLearner">No volver a mostrar esta sección</label>
                </div>
              </div>
            </div>
          </form>
          <div className="row mt-3 mb-5">
            <div className="col d-flex align-items-center justify-content-center">
              <div className="circuloIconoHome d-flex align-items-center justify-content-center"><img src={IconoHome} alt="" /></div>
              <span className="ml-2">Podrás acceder a esta sección en el menú superior de Inicio.</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RenderTemplateLanding;