const publicRoutes = [
  {
    path: "/login",
    loader: () => import("./web/login/index.js"),
  },
  {
    path: "/aviso",
    loader: () => import("./web/aviso/aviso.jsx"),
  },
  {
    path: "/recuperar",
    loader: () => import("./web/recuperar/recuperar.jsx"),
  },
  {
    path: "/reset/:username/:key",
    loader: () => import("./web/recuperar/reset.jsx"),
  }
].filter(Boolean);

/* ----------------------- CMS ----------------------- */

const cms_privateRoutes = [
  {
    path: "/cms",
    loader: () => import("./cms/home/index.js"),
  },
  {
    path: "/cms/grupos",
    loader: () => import("./cms/grupos/index.js"),
  },
  {
    path: "/cms/arbol",
    loader: () => import("./cms/arbol/index.jsx"),
  },
  {
    path: "/cms/programas",
    loader: () => import("./cms/programas/index.js"),
  },
  {
    path: "/cms/perfiles",
    loader: () => import("./cms/perfiles/index.js"),
  },
  {
    path: "/cms/recordatorios",
    loader: () => import("./cms/recordatorios/index.js"),
  },
  {
    path: "/cms/unidades",
    loader: () => import("./cms/unidades/index.js"),
  },
  {
    path: "/cms/modulos",
    loader: () => import("./cms/modulos/index.js"),
  },
  {
    path: "/cms/actividades",
    loader: () => import("./cms/actividades/index.js"),
  },
  {
    path: "/cms/usuarios",
    loader: () => import("./cms/usuarios/index.js"),
  },
  {
    path: "/cms/insignias",
    loader: () => import("./cms/insignias/index.jsx"),
  },
  {
    path: "/cms/avanceUsuarios",
    loader: () => import("./cms/avanceUsuarios/index.jsx"),
  },
  {
    path: "/cms/detalleUsuarioAnaliticos",
    loader: () => import("./cms/avanceUsuarios/indexDU.jsx"),
  },
  // {
  //   path: "/cms/detalleUsuarioReforzamiento",
  //   loader: () => import("./cms/avanceUsuarios/indexReforzamiento.jsx"),
  // },
  {
    path: "/cms/detalleUsuarioReforzamiento",
    loader: () => import("./cms/avanceUsuarios/indexReforzamiento.jsx"),
  },
  {
    path: "/cms/retoJobAnaliticos",
    loader: () => import("./cms/retoJob/index.jsx"),
  },
  {
    path: "/cms/jefe",
    loader: () => import("./cms/jefe/index.jsx"),
  },
  {
    path: "/cms/tutor",
    loader: () => import("./cms/tutor/index.jsx"),
  },
  {
    path: "/cms/soporte",
    loader: () => import("./cms/soporte/index.jsx"),
  },
  {
    path: "/cms/analiticos",
    loader: () => import("./cms/analiticosGenerales/index.jsx"),
  }
].filter(Boolean);

/* ----------------------- WEB ----------------------- */

const web_privateRoutes = [
  {
    path: "/",
    loader: () => import("./web/home/index.jsx"),
  },
  {
    path: "/modulos",
    loader: () => import("./web/modulos/index.jsx"),
  },
  {
    path: "/actividades",
    loader: () => import("./web/actividades/index.jsx"),
  },
  {
    path: "/objetos",
    loader: () => import("./web/objetos/index.jsx"),
  },
  {
    path: "/caso",
    loader: () => import("./web/casoEstudio/index.jsx"),
  },
  {
    path: "/reto",
    loader: () => import("./web/retoJob/index.jsx"),
  },
  {
    path: "/encuesta",
    loader: () => import("./web/encuesta/index.jsx"),
  },
  {
    path: "/evaluacion",
    loader: () => import("./web/evaluacion/index.jsx"),
  },
  {
    path: "/autoEvaluacion",
    loader: () => import("./web/autoEvaluacion/index.jsx"),
  },
  {
    path: "/detalleJefe",
    loader: () => import("./web/detalleJefe/index.jsx"),
  },
  {
    path: "/avisoLogeado",
    loader: () => import("./web/aviso/aviso.jsx"),
  },
  {
    path: "/avisoLogeadoJefe",
    loader: () => import("./web/aviso/aviso.jsx"),
  },
  {
    path: "/perfil",
    loader: () => import("./web/perfil/index.jsx"),
  },
  {
    path: "/jefe",
    loader: () => import("./web/jefe/index.jsx"),
  },
  {
    path: "/perfilJefe",
    loader: () => import("./web/perfilJefe/index.jsx"),
  },
  {
    path: "/evaluacionFinal",
    loader: () => import("./web/evaluacionFinal/index.jsx"),
  },
  {
    path: "/tutor",
    loader: () => import("./web/tutor/index.jsx"),
  },
  {
    path: "/miGrupo",
    loader: () => import("./web/tutor/miGrupo/index.jsx"),
  },
  {
    path: "/tutorRetos",
    loader: () => import("./web/tutor/retos/index.jsx"),
  },
  {
    path: "/tutorForos",
    loader: () => import("./web/tutor/foro/index.jsx"),
  },
  {
    path: "/tutorUsuarioReto",
    loader: () => import("./web/tutor/usuarioReto/index.jsx"),
  },
  {
    path: "/tutorObjetoForo",
    loader: () => import("./web/tutor/objetoForo/index.jsx"),
  },
  {
    path: "/tutorUsuarioDetalle",
    loader: () => import("./web/tutor/usuarioDetalle/index.jsx"),
  },
].filter(Boolean);

/* ---------------------- Export --------------------- */
const routes = {
  web_privateRoutes,
  cms_privateRoutes,
  publicRoutes
}

export default routes;
  