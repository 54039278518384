import React from 'react'
import './flipCard.css'

export default function FlipCard({front, back, isFlipped}) {
  return (
     <div className="bodyCard">
          <div className={`flipCard ${isFlipped ? 'flipped' : ''}`}>
               <div class="flipCard-inner">
                    <div class="flipCard-front">
                         {front}
                    </div>
                    <div class="flipCard-back">
                         {back}
                    </div>
               </div>
          </div>
     </div>
  )
}
