import React from "react";
import { BrowserRouter as Router, Switch, Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import CustomLoadable from "shared/CustomLoadable";
import routes from "views/routes";

import PublicRouter from "./PublicRouter";
import WebPrivateRouter from "./WEB_PrivateRouter";
import CmsPrivateRouter from "./CMS_PrivateRouter";

import { CONSTANT } from "../static/constants";
import AuthToken from "modules/shared/authToken";

import ScrollTop from "../shared/scrollTop/scrollTop";

export default function AppRouter() {

    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.auth);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if(localStorage.getItem(CONSTANT.AUTH_TOKEN) !== null){   
                if(!AuthToken.tokenStillValid()){ // Token invalido
                    // console.log( "Sesion finalizada por inactividad..")
                    window.location.replace("/");
                }
            }
        }, 1*1000); //15 segundos
        window.onbeforeunload = () => {
            // console.log(window.location + " Session removed")
            // localStorage.clear()
        }
        return () => clearInterval(interval);
    }, []);
    
    if (AuthToken.get() && !currentUser.isAuthenticated) {
        // FROM STORAGE
        const id = localStorage.getItem(CONSTANT.AUTH_USER_ID);
        const username = localStorage.getItem(CONSTANT.AUTH_USERNAME);
        const perfil = localStorage.getItem(CONSTANT.AUTH_PROFILE);
        const foto = localStorage.getItem(CONSTANT.AUTH_FOTO);
        const nombre = localStorage.getItem(CONSTANT.AUTH_NOMBRE);
        const apellido_paterno = localStorage.getItem(CONSTANT.AUTH_APELLIDO_P);
        const apellido_materno = localStorage.getItem(CONSTANT.AUTH_APELLIDO_M);
        const createdAt = localStorage.getItem(CONSTANT.AUTH_CREATEDAT);
        const landingLearner = localStorage.getItem(CONSTANT.AUTH_LANDING_LEARNER);
        const landingJefe = localStorage.getItem(CONSTANT.AUTH_LANDING_JEFE);
        dispatch({ type: CONSTANT.AUTH_EMPLOYEE_ID_VERIFICATION_START, payload: username });
        dispatch({ type: CONSTANT.AUTH_EMPLOYEE_ID_VERIFICATION_SUCCESS, payload: { id: id, perfil, foto, nombre, apellido_paterno, apellido_materno, createdAt, landingLearner, landingJefe } });
    }

    return (
        <Router>
            <ScrollTop>
                <Switch>
                    {routes.publicRoutes.map((route) => (
                        <PublicRouter
                            currentUser={currentUser}
                            key={route.path}
                            exact
                            path={route.path}
                            component={CustomLoadable({
                                loader: route.loader,
                            })}
                        />
                    ))}
                    {routes.web_privateRoutes.map((route) => (
                        <WebPrivateRouter
                            currentUser={currentUser}
                            key={route.path}
                            exact
                            path={route.path}
                            component={CustomLoadable({
                                loader: route.loader,
                            })}
                        />
                    ))}
                    {routes.cms_privateRoutes.map((route) => (
                        <CmsPrivateRouter
                            currentUser={currentUser}
                            key={route.path}
                            exact
                            path={route.path}
                            component={CustomLoadable({
                                loader: route.loader,
                            })}
                        />
                    ))}
                    <Redirect to="/login" />
                </Switch>
            </ScrollTop>
        </Router>

    );
}
