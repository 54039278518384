import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap';
import { CONSTANT } from '../../../static/constants';
import axios from 'axios';
import Formulario from '../../../static/formularioRetroJefe.json'
import Formulario2 from '../../../static/formularioRetroJefe2.json'
import Formulario3 from '../../../static/formularioRetroJefe3.json'
import flechaAcordeon from '../../../assets/img/iconos/flechaAcordeon.svg'
import _ from "lodash";

export default function AcordeonFormulario({funcion}) {

     const dispatch = useDispatch();
     const storeJefe = useSelector((state) => state.rutaJefe);
     const [showGuardado, setShowGuardado] = useState( false );
     const [showEnviado, setShowEnviado] = useState( false );
     const { register, handleSubmit } = useForm();


     const handleCloseGuardado = () => {
          setShowGuardado(false);
     }

     const handleCloseEnviado = () => {
          setShowEnviado(false);
     }

     const backOne = (e) => { 
          e.preventDefault()
          setShowGuardado(false);
          setShowEnviado(false);
          dispatch({ type: CONSTANT.RUTA_JEFE_SET_VISTA_ACTIVE, payload: {vista: 'uno'} })
     }


     let _form;

     switch (storeJefe.dataUnidad.orden) {
          case 0:
               _form = _.cloneDeep(Formulario);
               break;
          case 1:
               _form = _.cloneDeep(Formulario2);
               break;
          case 2:
               _form = _.cloneDeep(Formulario3);
               break;
          default:
               break;
     }

     let requerido = false;
     if(storeJefe.dataUsuario.evaluacion_jefe !== null && typeof storeJefe.dataUsuario.evaluacion_jefe !== "undefined" && storeJefe.dataUsuario.evaluacion_jefe !== "" && storeJefe.dataUsuario.evaluacion_jefe !== "{}"){
          _form = JSON.parse(storeJefe.dataUsuario.evaluacion_jefe) 
          requerido = true;
     }

     const DateFix = (fecha, hora) => {
          var uFecha2 = fecha + ' ' +  hora
          var arr2 = uFecha2.split(/[- :]/);
          return new Date(arr2[0], arr2[1]-1, arr2[2], arr2[3], arr2[4], arr2[5]);
      }

     let inicioEv1a1 = DateFix( storeJefe.dataUsuario.inicio1a1, " 00:00:00" ) < new Date();

     

     let enviado = storeJefe.dataUsuario.evaluacion_jefe_enviada === 1;

     const [form, setForm] = useState(_form)
     const [requeridoF, setRequerido] = useState( inicioEv1a1 );


     // const [promedio, setPromedio] = useState(0)

     const onSubmit = datos => {
          let _promedio = updateForm(datos)
          saveForm( 0, _promedio )
     }

     const onSubmitSaveAndClose = datos => {
          let _promedio = updateForm(datos)
          saveForm( 1, _promedio )
     }

     const updateForm = (datos) => {
          let valores = []
          form[0].secciones.forEach(seccion => {
               seccion.seccion.preguntas.forEach(pregunta => {
                    if (pregunta.pregunta.tipo === "opcionMultiple") {
                         pregunta.pregunta.respuestas.forEach(respuestaGrupo => {
                              if(respuestaGrupo.respuesta === datos[respuestaGrupo.grupo]){
                                   pregunta.respuesta = datos[respuestaGrupo.grupo]
                                   valores.push( respuestaGrupo.valor )
                              }
                         })
                    }
               })
          })
          setForm(form);
          // setPromedio( valores.reduce((a,b) => a + b, 0) / valores.length )
          return valores.reduce((a,b) => a + b, 0) / valores.length
     }

     const saveForm = ( ev_enviada, promedio ) => {
          const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);
          
          axios({
               method: 'POST',
               url: process.env.REACT_APP_API_URL + `/api/guarda_evaluacion_jefe`,
               data: { 
                    id_usuario: storeJefe.usuario,
                    id_unidad: storeJefe.unidad,
                    id_grupo: storeJefe.grupo,
                    promedio: promedio,
                    evaluacion: JSON.stringify( form ),
                    ev_enviada: ev_enviada
               },
               headers: { 
                   'Authorization': `${token}`,
                   "Content-Type": "application/json",
                   'Cache-Control': 'no-cache',
                   'Pragma': 'no-cache',
                   'Expires': '0'
               }
           })
           .then(res => { 
               if(res.status===200){
                    let tmp = storeJefe.dataUsuario
                    tmp.evaluacion_jefe = JSON.stringify( form )
                    tmp.evaluacion_jefe_enviada = ev_enviada
                    tmp.promedio_calificacion = promedio
                    dispatch({ type: CONSTANT.RUTA_JEFE_SET_DATA_USUARIO, payload: tmp })
                    if(ev_enviada === 0){
                         setShowGuardado(true);
                    }else{
                         setShowEnviado(true);
                    }

                    _form.map((data, key) => {
                         data.secciones.map((seccion, key_seccion) => {
                              seccion.seccion.preguntas.map((pregunta, key_pregunta) => {
                                   pregunta.pregunta.respuestas.map((respuesta, key3) => {
                                        pregunta.respuesta = ''
                                   })
                              })
                         })
                    })
                    
               }else{
                   // alert("Ocurrio un error al guardar.");
               }
           })
           .catch(err => { 
               console.log(err)
               // alert("Ocurrio un error al guardar.");
           });
     }

     const ModalGuardado = () => {
          return (
               <Modal show={showGuardado} onHide={handleCloseGuardado} animation={false} size='lg' centered>
                    <Modal.Title>
                    <div className="row">
                         <div className="d-flex col align-items-center justify-content-end">
                              <button className="btn botonModal" onClick={handleCloseGuardado}><i className="far fa-times-circle cerrarModal"></i></button>
                         </div>
                    </div>
                    </Modal.Title>
                    <Modal.Body>
                         <div className="row">
                              <div className="col">
                                   <div className="d-flex align-items-center justify-content-center">
                                        <i className="fas fa-check-circle iconoModalCheck"></i>
                                   </div>
                              </div>
                         </div>
                         <div className="row">
                              <div className="col">
                                   <div className='d-flex align-items-center justify-content-center'>
                                        <div className="d-flex align-self-center justify-content-center"><span className="textoVerdeModalJefe"><br />GUARDADO CORRECTAMENTE</span></div>
                                   </div>
                              </div>
                         </div>
                         <div className="row">
                              <div className="offset-1 col-10">
                                   <div className="row">
                                          <div className="d-flex align-self-center justify-content-start"><span className="textoModal">Podrás utilizar las respuestas que has guardado o modificarlas antes de enviarlas como calificación final.</span></div>
                                   </div>
                              </div>
                         </div>
                         <div className="row aire">
                              <div className="col d-flex align-self-center justify-content-center">
                                  <button onClick={(e) => backOne(e)} className="btn btn-verdeModalJefe">CONTINUAR</button>
                              </div>
                         </div>
                    </Modal.Body>
               </Modal>
          );
      }

      const ModalEnviado = () => {
          return (
               <Modal show={showEnviado} onHide={handleCloseEnviado} animation={false} size='lg' centered>
                    <Modal.Title>
                    <div className="row">
                         <div className="d-flex col align-items-center justify-content-end">
                              <button className="btn botonModal" onClick={handleCloseEnviado}><i className="far fa-times-circle cerrarModal"></i></button>
                         </div>
                    </div>
                    </Modal.Title>
                    <Modal.Body>
                         <div className="row">
                              <div className="col d-flex align-items-center justify-content-center">
                                   <i className="fas fa-check-circle iconoModalCheck"></i>
                              </div>
                         </div>
                         <div className="row">
                              <div className="col d-flex align-items-center justify-content-center">
                                  <span className="textoVerdeModalJefe"><br />ENVIADO</span>
                              </div>
                         </div>
                         <div className="row">
                              <div className="offset-1 col-10 d-flex align-items-center justify-content-start textoModal">
                                   Para completar el proceso de retroalimentación, documenten en conjunto ACCIONES y COMPROMISOS.
                              </div>
                         </div>
                         <div className="row aire">
                              <div className="col d-flex align-self-center justify-content-center">
                                  <button onClick={(e) => funcion(e, 'dos')} className="btn btn-verdeModalJefe">CONTINUAR</button>
                              </div>
                         </div>
                    </Modal.Body>
               </Modal>
          );
      }

     return (
          <React.Fragment key={`formJefe`}>
               {showGuardado ? <ModalGuardado /> : null}
               {showEnviado ? <ModalEnviado /> : null}
               {form.map((data, key) => {
                    return (
                         <>
                              <form onSubmit={handleSubmit(onSubmit)}>
                                   <div className="row aire" key={key}>
                                        <div className="col">
                                             <span className='tituloUnidadFormRetroJefe'>{data.unidad}</span>
                                        </div>
                                   </div>
                                   <div className="row aire">
                                        <div className="col">
                                             {data.parrafos.map((parrafo, key_parrafo) => {
                                                  return (
                                                       <>
                                                            <p key={key_parrafo} className='parrafoEncabezadoFormRetroJefe'>{parrafo.parrafo.texto}</p>
                                                       </>
                                                  );
                                             })}
                                        </div>
                                   </div>
                                   {data.secciones.map((seccion, key_seccion) => {
                                        return (
                                             <React.Fragment key={`formJefe_Seccion_` + key_seccion}>
                                                  <div className="accordion acordeonRetroJefe aire" id="accordionExample">
                                                       <div className="card" key={key_seccion}>
                                                            <div className="card-header" id="headingOne">
                                                                 <button className="btn btn-block text-left" type="button" data-toggle="collapse" data-target={`#Collapse_${seccion.seccion.titulo.replace(/\s/g, "")}`} aria-expanded="false" aria-controls={`Collapse_${seccion.seccion.titulo.replace(/\s/g, "")}`}>
                                                                      <div className="row">
                                                                           <div className="col-10">
                                                                                <span className='tituloAcordeonRetroJefe'>
                                                                                     {seccion.seccion.titulo}
                                                                                </span>
                                                                           </div>
                                                                           <div className="col">
                                                                                <div className="d-flex align-items-center justify-content-end">
                                                                                     <img src={flechaAcordeon} alt="" className="flechaAcordeon" />
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </button>
                                                            </div>
                                                            <div id={`Collapse_${seccion.seccion.titulo.replace(/\s/g, "")}`} className="collapse resultadosAcordion" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                                 <div className="card-body">
                                                                      <div className="row">
                                                                           <div className="col">
                                                                                <span className="textoEncabezadoSeccionFormRetroJefe">{seccion.seccion.texto}</span>
                                                                           </div>
                                                                      </div>
                                                                      {seccion.seccion.preguntas.map((pregunta, key_pregunta) => {
                                                                           return (
                                                                                <React.Fragment key={'Pregunta' + key_pregunta}>
                                                                                     <div className="row aire">
                                                                                          <div className="col">
                                                                                               <span className="textoPreguntaFormRetroJefe">{pregunta.pregunta.titulo}</span>
                                                                                          </div>
                                                                                     </div>
                                                                                     <div className="row aire">
                                                                                          {pregunta.pregunta.respuestas.map((respuesta, key3) => {
                                                                                               const checked = respuesta.respuesta === pregunta.respuesta && storeJefe.evaluacion_jefe !== null ? true : false
                                                                                               return (
                                                                                                    <React.Fragment key={'resCon' + key3}>
                                                                                                         <div className="row">
                                                                                                              <div className="col">
                                                                                                                   <div className="form-check">
                                                                                                                        <div className="align-items-center">
                                                                                                                             <input key={storeJefe.dataUsuario.id + "_" + key3} {...register(respuesta.grupo, { required: requeridoF })} className="form-check-input checkMarkFormRetroJefe" type="radio" name={respuesta.grupo} id={respuesta.nombreInput} value={respuesta.respuesta} defaultChecked={checked} disabled={enviado}/>
                                                                                                                             <label className="form-check-label labelRespuestaFormRetroJefe" htmlFor={respuesta.nombreInput}>
                                                                                                                                  {respuesta.respuesta}
                                                                                                                             </label>
                                                                                                                        </div>
                                                                                                                   </div>
                                                                                                              </div>
                                                                                                         </div>
                                                                                                    </React.Fragment>
                                                                                               );
                                                                                          })}
                                                                                     </div>
                                                                                </React.Fragment>
                                                                           );
                                                                      })}
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </React.Fragment>
                                        );
                                   })}
                                   <div className="row aire">
                                        { !inicioEv1a1 && (
                                             <div className="col d-flex align-items-center justify-content-center">
                                                  <button type='submit' className='btn btn-grisFormRetroJefe'>GUARDAR BORRADOR</button>
                                             </div>
                                        )}
                                        { inicioEv1a1 &&(
                                             <div className="col d-flex align-items-center justify-content-center">
                                                  <button onClick={handleSubmit(onSubmitSaveAndClose)} className='btn btn-verdeFormRetroJefe' disabled={storeJefe.dataUsuario.evaluacion_jefe_enviada === 1} >ENVIAR EVALUACIÓN</button>
                                             </div>
                                        )} 
                                   </div>
                              </form>
                         </>
                    );
               })}
          </React.Fragment>
     )
}
