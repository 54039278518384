import React from 'react'
import { Modal } from 'react-bootstrap';
import Fondo from '../../../assets/img/FondoInsignias.png'
import Lottie from 'react-lottie';
import './DetalleInsignia.css'

export default function DetalleInsignia({show, setShow, insignia, nivel}) {
     const handleClose = () => {
          setShow(false);
     }
     var img = { fileName: '', fileUrl: '' };
     if (typeof (insignia.imagen) == "string") {
          try {
               img = JSON.parse(insignia.imagen)
          } catch (e) { }
     }
     var lottie = { fileName: '', fileUrl: '' };
     if (typeof (insignia.lottie) == "string") {
          try {
               lottie = JSON.parse(insignia.lottie)
          } catch (e) { }
     }
     if(insignia.tipo === 2 && insignia.nivel > 1){
          if (typeof (insignia["imagenNivel"+insignia.nivel]) == "string") {
               try {
                   img = JSON.parse(insignia["imagenNivel"+insignia.nivel])
               } catch (e) { }
           }
           if (typeof (insignia["lottieNivel"+insignia.nivel]) == "string") {
               try {
                   lottie = JSON.parse(insignia["lottieNivel"+insignia.nivel])
               } catch (e) { }
           }
     }
     let nombreInsignia = insignia.nombre;
     if(insignia.tipo === 2 && insignia.nivel > 1){
          nombreInsignia = insignia["nombreNivel"+insignia.nivel]
     }

     const defaultOptions = {
          loop: true,
          path: lottie.fileUrl,
          autoplay: true,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
     };

  return (
     <Modal show={show} onHide={handleClose} animation={false} size='lg' centered className='modalDetalleInsignia'>
          <Modal.Title>
               <div className="row">
                    <div className="d-flex col align-items-center justify-content-end">
                         <button className="btn botonModal" onClick={handleClose}><i className="far fa-times-circle cerrarModal"></i></button>
                    </div>
               </div>
          </Modal.Title>
          <Modal.Body>
               <div className="row">
                    <div className="col d-flex align-items-center justify-content-center">
                         {lottie.fileUrl!=='' && 
                              <Lottie options={defaultOptions} height={202} width={223} />
                         }
                         {lottie.fileUrl==='' && 
                              <img src={img.fileUrl} className={''} width="223" height="202" alt="imagen insignia"/>
                         }
                    </div>
               </div>
               <div className="row aire">
                    <div className="col d-flex align-items-center justify-content-center">
                         <span className='textoRojoModalInsignia'>¿QUÉ DEBES HACER PARA OBTENERLA?</span>
                    </div>
               </div>
               <div className="row aire">
                    <div className="col-12 d-flex align-items-center justify-content-center text-center ">
                         <span className='divGrisInsignia textNombreInsigniaModal p-3'>{nombreInsignia}</span>
                    </div>
               </div>
               <div className="row aire">
                    <div className="offset-2 col-8 d-flex align-items-center justify-content-center">
                         <span className='textoDescripcionInsigniaModal'>{insignia.descripcion}</span>
                    </div>
               </div> 
               <br />
               <br />
               <br />
          </Modal.Body>
     </Modal>
  )
}
