import React from "react";

import "./loadingSpinner.css";
import lottie from "lottie-web";
import loadingLoottie from "../../../assets/lotties/LottieDescargando_Cedis.json";

const LoadingSpinner = () => {
  
  React.useEffect(() => {
    lottie.loadAnimation({
      loop:           true, // boolean
      autoplay:       true, // boolean
      animationData:  loadingLoottie,
      container:      document.querySelector("#loadingContainer"),
    });
  }, []);

  return (
      <>
      {/*<div className="loading loading--full-height"></div>*/}
      <div className="loading loading--full-height" id="loadingContainer" />
      </>
  );
};

export default LoadingSpinner;
